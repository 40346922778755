import {
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { colors } from "../colors";

const themeConfig = {
  palette: {
    primary: {
      main: colors.black.main,
      light: colors.black.light,
      dark: colors.black.dark,
      contrastText: colors.contrastText.light
    },
    secondary: {
       main: colors.white.main,
       light: colors.white.light,
       dark: colors.white.dark,
       contrastText: colors.contrastText.dark
    }
  },
  typography: {
    fontFamily: 'Georgia, Cambria',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Georgia';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }
      `,
    }
  }
}
// const themeConfig = {
//
//   typography: {
//     fontFamily: 'Georgia, Cambria',
//   },
//   components: {
//     MuiCssBaseline: {
//       styleOverrides: `
//         @font-face {
//           font-family: 'Georgia';
//           font-style: normal;
//           font-display: swap;
//           font-weight: 400;
//         }
//       `,
//     }
//   }
// }
//



const theme = createTheme(themeConfig);

export function MyThemeProvider({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
