import React, { useEffect, useState } from "react";

import {  useNavigate } from "react-router-dom";
import { useRealmApp } from "../RealmApp";
import { useNavore } from "../../hooks/useNavore";
import {Snackbar, Box, Skeleton, Alert, Container, Typography} from "@mui/material";



import { useShowLoader } from "../../hooks/util-hooks";
import { useTranslation } from 'react-i18next';
import AsyncImage from "../common/AsyncImage";
import { VerticalSpacing } from "../common/Common";
import placeholder from '../../Assets/navore_placeholder.webp';
import { days } from "../../utils";
import {AppName} from "../common/AppName"
import { CertificationTable } from "../Certifications/CertificationTable";
import { ProducerTable } from "./ProducerTable";

export function ProducerApproval() {

    const { currentUser, updateViewLabel, isAdmin } = useRealmApp();
    const {loading, privateProfile, getAllProducers, getAllCertifications} = useNavore()

    const navigate = useNavigate()
    const { t } = useTranslation();

    const noResults = {
      success: null,
      error:null
    };

    const [actionResults, setActionResults] = React.useState(noResults);
    const [certifications, setCertifications] = React.useState(null)
    const [organizations, setOrganizations] = React.useState(null)
    const [filteredOrganizations, setFilteredOrganizations] = React.useState(null)


    useEffect(() => {

      updateViewLabel(t('certifications'))
      if(loading)return;
      if(!currentUser){
        navigate("/", { replace: true });
      }

      if(privateProfile && !isAdmin){
        navigate("/unauthorized", { replace: true });
      }

      if(privateProfile && organizations === null){
        getProducers()
      }

    }, [loading, currentUser, privateProfile, actionResults, certifications]);

    function handleSnackbarClose(){
      setActionResults(noResults);
    }

    function snackbarOpen(){
      return (Boolean(actionResults.success) || Boolean(actionResults.error))
    }

    
    const getProducers = async() => {
      let orgs = await getAllProducers()
      setOrganizations(orgs)
      setFilteredOrganizations(orgs)
    }



    const organizationSelected = (organization) =>{
      let path = "/certifications/" + organization.producer_id 
      navigate(path, { replace: false });
    }

    const organizationApproved = () =>{
      
    }


    const handleSearch = (value) =>{

      let filtered = organizations.filter(org => (org.name.includes(value) || org.email.includes(value)));
      setFilteredOrganizations(filtered)
    }

    return (
      <Container className="main-container" maxWidth={false}>
        <Container maxWidth='sm' disableGutters={true}>
          <VerticalSpacing value='20px'/>

            {organizations &&
              <ProducerTable onSearch={(value) => handleSearch(value)} organizations={filteredOrganizations} onSelect={(selected) => organizationSelected(selected)} onApprove={(selected) => organizationApproved(selected)}/>
            }

        </Container>

        <Snackbar open={snackbarOpen()} autoHideDuration={4000} onClose={handleSnackbarClose}>
            {snackbarOpen() ? <Alert variant="filled" severity={actionResults.success ? "success" : "error"}>
            {actionResults.success ? actionResults.success : actionResults.error}
           </Alert> : <div/>}

        </Snackbar>
      </Container>

    );
}
