import React, { useEffect, useState } from "react";
import { useRealmApp } from "../RealmApp";
import { useNavore } from "../../hooks/useNavore";
import { useNavigate } from "react-router-dom";


// IMPORTS
import { Stack, Snackbar, Alert , TextField, Typography, Button, Container} from "@mui/material";
import { AdminAccessTable } from "./AdminAccessTable";
import { VerticalSpacing } from "../common/Common"


import { useShowLoader } from "../../hooks/util-hooks";
import { useTranslation } from 'react-i18next';



export function AdminAccess() {

    const navigate = useNavigate()
    const { t } = useTranslation();

    // Authentication errors
    const noResults = {
      success: null,
      error:null,
    };
    

    const { currentUser, updateViewLabel, isAdmin } = useRealmApp();
    const { loading, privateProfile, getAllTemporaryAdmins } = useNavore()
    
    // const [admins, setAdmins] = React.useState(null)
    const [tempAdmins, setTempAdmins] = React.useState(null)
    const [tempAdminsMaster, setTempAdminsMaster] = React.useState(null)
    const [actionResults, setActionResults] = React.useState(noResults);

    const showLoader = useShowLoader(loading, 200);


    useEffect(() => {

      updateViewLabel('Admins')
      if(loading)return;
      if(!currentUser){
        navigate("/", { replace: true });
      }

      if(privateProfile && !isAdmin){
        navigate("/unauthorized", { replace: true });
      }
      
      if(privateProfile !== null && tempAdmins === null){
        getAllAdmins()
      }

    }, [loading, currentUser, actionResults, privateProfile, tempAdmins]);


    async function getAllAdmins(){
      let tempAdmins = await getAllTemporaryAdmins()

      setTempAdmins(tempAdmins)
      setTempAdminsMaster(tempAdmins)
    }


    async function revokeTempAdmin(selected){

      setActionResults(noResults)
      console.log("removing...", selected)
      
      const functionName = "editSiteAdmins";
      const args = [privateProfile.uid, selected, 'remove'];

      let result = await currentUser.callFunction(functionName, ...args);
      if(result === null){
        setActionResults(() => ({
          success:null,
          error: "Failed to remove Associates",
        }));
        return
      }else if(result.success === false){
        setActionResults(() => ({
          success:null,
          error: result.message
        }));

        return
      }else if (result.success === true){

        // let tadmins = JSON.parse(JSON.stringify(admins))
        // let newAdmins = []
        // for(const admin of tadmins){
        //   if(!selected.includes(admin._id) ){
        //     newAdmins.push(admin)
        //   }
        // }
        // setAdmins(newAdmins)

        setActionResults(() => ({
          error:null,
          success: "Associate(s) Successfully Removed"
        }));
      }

    }


    function handleSnackbarClose(){
      setActionResults(noResults);
    }

    function snackbarOpen(){
      return (Boolean(actionResults.success) || Boolean(actionResults.error))
    }

    const handleSearch = (value) =>{

      let filtered = tempAdmins.filter(access => (access.producer_id.name.includes(value) || access.uid.email.includes(value)));
      setTempAdmins(filtered)
    }

    
    const modalStyle = {
      width: "95%",
      transform: 'translate(0%, 25%)',
      bgcolor: 'background.paper',
      boxShadow: 24,
      borderRadius: '16px',
      padding:"5px",
      align:"right"
    };

    
    return (
      <Container className="main-container" maxWidth={false} disableGutters={true}>

        <VerticalSpacing value="20px"/>

        <Container maxWidth='sm' disableGutters>

              <Typography align="left" variant="h5" color="primary">
                  {t("temporaryProducerAccess")}
              </Typography>

         </Container>

        <VerticalSpacing value='10px'/>

        <Container maxWidth='sm' disableGutters>

              {tempAdmins && <AdminAccessTable adminAccess={tempAdmins} currentUser={privateProfile} onSearch={(value) => handleSearch(value)}  remvokeAdminAccess={(selected) => revokeTempAdmin(selected)}/>}

              <Snackbar open={snackbarOpen()} autoHideDuration={3000} onClose={handleSnackbarClose} >

                  {snackbarOpen() ? <Alert variant="filled" severity={actionResults.success ? "success" : "error"}>
                    {actionResults.success ? actionResults.success : actionResults.error}
                  </Alert> : <div/>}

              </Snackbar>

        </Container>

  </Container>
  );
}
