import React from "react";
import * as Realm from "realm-web";


import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";


import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useRealmApp } from "../RealmApp";
import { MoreInfoItem } from "../common/MoreInfo";
import { toggleBoolean } from "../../utils";
import { useErrorAlert } from "../../hooks/useErrorAlert";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export function SendReset() {

  const realmApp = useRealmApp();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // Track whether the user is logging in or signing up for a new account

  React.useEffect(() =>{



  }, [realmApp])

  // Authentication errors
  const noErrors = {
    email: null,
    password: null,
    other: null,
  };

  const [error, setError] = React.useState(noErrors);

  const clearErrors = () => setError(noErrors);
  const NonAuthErrorAlert = useErrorAlert({
    error: error.other,
    clearError: () => {
      setError((prevError) => ({ ...prevError, other: null }));
    },
  });
  // Manage password visibility
  const [showPassword, setShowPassword] = React.useState(false);
  const toggleShowPassword = () => setShowPassword(toggleBoolean);
  const [emailSent, setEmailSent] = React.useState(false)
  const [receivingEmail, setReceivingEmail] = React.useState("")

  const onFormSubmit = async ({ email }) => {
    clearErrors();

    if(email !== undefined && email !== ""){
      console.log(email)
      await realmApp.emailPasswordAuth.sendResetPasswordEmail( email)

      setReceivingEmail(email)
      setEmailSent(true)

    }

  };


  const toLogin = () =>{
    navigate("/login", {replace:true})
  }


  return (

    <Container className="main-container">
      <Container maxWidth="sm">

      <Card className="auth-card">
        <form
          className="auth-form"
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            const { email } = Object.fromEntries(formData.entries());
            onFormSubmit({ email });
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            {t('passwordReset')}
          </Typography>

          <NonAuthErrorAlert />

          {!emailSent && <TextField
            id="input-email"
            name="email"
            label={t('emailAddress')}
            variant="outlined"
            error={Boolean(error.email)}
            helperText={error.email ?? ""}
          />}

          {!emailSent ? <Button type="submit" variant="contained" color="primary">
            {t('sendResetEmail')}
          </Button> : <Typography component="h5" gutterBottom>
            {t("passwordResetEmailSentTo") + receivingEmail}
          </Typography> }

        </form>

      </Card>

      <MoreInfoItem>
        <button className="link-button" onClick={() => toLogin()}>
          {t('backToLogin')}
        </button>
      </MoreInfoItem>

      </Container>
    </Container>
  );
}
