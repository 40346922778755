import React, {useState} from 'react'
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import AsyncImage from "./AsyncImage";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Navigate, useNavigate } from "react-router-dom";
import { useRealmApp } from "../RealmApp";
// import { makeStyles } from "@mui/styles";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useTranslation } from 'react-i18next';


// const useStyles = makeStyles({
//   root: {
//     paddingLeft: 56,
//     paddingRight: 56
//   },
//   rootAbsolute: {
//     paddingLeft: 66,
//     paddingRight: 56,
//     position: "relative",

//     "& .MuiButton-sizeSmall": {
//       paddingLeft: 48,
//       paddingRight: 32,
//       position: "absolute",
//       left: 16
//     },
//     "& .MuiButton-startIcon": {
//       position: "absolute",
//       left: 16
//     }
//   },
//   rootNegativeMargin: {
//     paddingLeft: 56,
//     paddingRight: 56,
//     "& .MuiButton-startIcon": {
//       marginLeft: -46,
//       marginRight: 36
//     }
//   }
// });




export const EditMultilineField = ({value, label, placeholder, handleSave}) => {
  
  
  let {t} = useTranslation()

  const [editing, setEditing] = useState(false)
  const [text, setText] = useState(value)
  const originalText = value

  const startEditing = (event) =>{
    if(editing){
      setText(event.target.value);
      return
    }
    setText(event.target.value);
    setEditing(true)
  }

  const setNew = () =>{
    if(text == null){return}

    handleSave(text)

    setText(text)
    setEditing(false)
  }

  const cancelEditing = () =>{
    setText(originalText)
    setEditing(false)
  }
  
  return(
    <Grid container justify="flex-end" >
      <TextField
        variant='outlined'
        id="standard-textarea"
        label={placeholder}
        placeholder={placeholder}
        value={text}
        onChange={startEditing}
        fullWidth
        multiline
      />
      {editing && <VerticalStandardSpacing/>}
      {editing && <Grid container justifyContent='flex-end' direction="row">
            
        <Button  variant="contained" onClick={setNew}>
          {t("save")}
        </Button>
        <HorizontalStandardSpacing/>
        <Button sx={{backgroundColor:'white'}} variant="text" onClick={cancelEditing}>
            {t("cancel")}
        </Button>

      
      </Grid>}
  </Grid> )
}





export const EditSingleLineField = ({value, label, fullWidth, endLabel, startLabel, type, handleSave}) => {
  
  
  let {t} = useTranslation()

  const [editing, setEditing] = useState(false)
  const [text, setText] = useState(value)
  const originalText = value

  const startEditing = (event) =>{
    if(editing){
      setText(event.target.value);
      return
    }
    setText(event.target.value);
    setEditing(true)
  }

  const setNew = () =>{
    if(text == null){return}

    handleSave(text)

    setText(text)
    setEditing(false)
  }

  const cancelEditing = () =>{
    setText(originalText)
    setEditing(false)
  }
  
  return(
    <Grid container justify="flex-end">
      <TextField
        variant='outlined'
        id="standard-textarea"
        type={type != null ? type : 'text'}
        label={label}
        placeholder={label}
        value={text}
        onChange={startEditing}
        fullWidth={fullWidth != null ? fullWidth : true}
        InputProps={{
          startAdornment: <InputAdornment position="start">{startLabel != null ? startLabel : ""}</InputAdornment>,
          endAdornment: <InputAdornment position="end">{endLabel != null ? endLabel : ""}</InputAdornment>,
        }}
      />
      {editing && <VerticalStandardSpacing/>}
      {editing && <Stack direction="row">
            
        <Button  variant="contained" onClick={setNew}>
          {t("save")}
        </Button>
        <HorizontalStandardSpacing/>
        <Button sx={{backgroundColor:'white'}} variant="text" onClick={cancelEditing}>
            {t("cancel")}
        </Button>

      
      </Stack>}
  </Grid> )
}


export const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


export function MediaGalleryImageList ({images, onSelect}) {

  
  let columns = 3

  if(window.innerWidth > 768){
    columns = 5

  }

  return (
    <ImageList  sx={{backgroundColor:'F8F0E3', width:'100%', height: '100%' }} cols={columns} rowHeight='auto'>

      {images.map((item) => (

        <ImageListItem sx={{cursor:'auto'}} onClick={onSelect !== undefined ? () => onSelect(item) : undefined} key={item}>
          
          <div style={{ width:'100%', height: 'auto', aspectRatio:'1/1'}}>
            <AsyncImage
              width='100%'
              height='100%'
              objectFit='contain'
              src={item}
              alt={item}
            />
          </div>

        </ImageListItem>
      ))}
    </ImageList>
  );
}


//For Select Image Modal Views
export function MediaSelectGalleryImageList({images, onSelect}) {

  let columns = 3
  let rowHeight = 111
  if(window.innerWidth > 768){
    columns = 5
    rowHeight = 180
  }

  let containerHeight = (rowHeight * 3) + 18
  return (

    <ImageList  sx={{ width:'100%', height: containerHeight }} cols={3} rowHeight={rowHeight}>

      {images.map((item) => (

        <ImageListItem sx={{cursor:'pointer'}} onClick={onSelect !== undefined ? () => onSelect(item) : undefined} key={item}>
          
            <AsyncImage
              width={`${rowHeight}px`}
              height={`${rowHeight}px`}
              objectFit='contain'
              src={item}
              alt={item}
            />

        </ImageListItem>
      ))}
    </ImageList>
  );
}


//For Select Image Modal Views
export const MediaMultipleSelectGalleryImageList = ({images, onSelect}) => {

  const [selected, setSelected] = React.useState([])

  React.useEffect(() => {
    console.log("EFFECT Selected: ", selected)
  }, [selected]);

  let columns = 3
  let rowHeight = 111
  if(window.innerWidth > 768){
    columns = 5
    rowHeight = 180
  }  
  let containerHeight = (rowHeight * 3) + 18


  const handleSelection = (item) => {

    if(selected.includes(item)){
        let tmp = selected
        console.log("REMOVING")
        tmp = tmp.filter(v => !tmp.includes(item))
        setSelected(tmp)
        onSelect(tmp)
    }else{
        let tmp = selected
        console.log("PUSHING")
        tmp.push(item)
        setSelected(tmp)
        onSelect(tmp)
    }
  }

  return (
   <Card></Card>
  );
}


export function LeftBarSpacer() {

    return (
      <Button
        variant="text"
        color="primary"
      >
        <Typography variant="button"></Typography>
      </Button>

    );
}

export function RightBarSpacer() {

  return (
    <Button
      color="primary"
    >
    </Button>

  );
}


export function VerticalSpacing({value}) {

  return (
    <Box
    sx={{
      width: '100%',
      height: value
    }}
  />

  );
}
export function HorizontalSpacing({value}) {

  return (
    <Box
    sx={{
      width: value,
      height: '100%'
    }}
  />

  );
}

export function VerticalStandardSpacing() {

  return (
    <Box
    sx={{
      width: '100%',
      height: 16
    }}
  />

  );
}
export function HorizontalStandardSpacing() {

  return (
    <Box
    sx={{
      width: '100%',
      width: 8
    }}
  />

  );
}
export function ThinLine({width}) {

    return (
      <Box
        sx={{
          width: '100%',
          height: 2,
          backgroundColor: 'primary.main'
        }}
      />

    );
}

export function ListButton({onClick, title}) {
  return (
    <Box padding='10px'>
      <Container disableGutters={true} sx={{  
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '6vh',
        cursor:'pointer',
        textTransform: 'none',
        width: '100%'}}
        onClick={() => onClick()}>
        <Button
          style={{textTransform: 'none'}}
          variant="text"
          color="primary"
        >
        <Typography variant="button">{title}</Typography>
        </Button>

        <ChevronRightIcon color='primary'/>
      </Container>

      <ThinLine/>
    </Box>
  );
}


export function LoginButton() {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { currentUser, logOut, isAnonymous } = useRealmApp()

    const toLogin = async() =>{
      navigate("/login", {replace:true})
    }


    return (
      <ListButton
        title={t('login')}
        onClick={() => toLogin()}
      >
      </ListButton>

    );
}



