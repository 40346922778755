import React from "react";
import * as Realm from "realm-web";
import databaseInfo from "../realm.json";
import { BSON } from "realm-web";
import { t } from "i18next";
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";
import jwt_decode from "jwt-decode";


function createRealmApp(id) {
  return  Realm.getApp(id);
}


async function getValidAccessToken(realmApp) {

  // Guarantee that there's a logged in user with a valid access token
  if (!realmApp.currentUser) {
    // If no user is logged in, log in an anonymous user. The logged in user will have a valid
    // access token.
    
    await realmApp.logIn(Realm.Credentials.anonymous());

  } else {

    const { exp } = jwt_decode(realmApp.currentUser.accessToken);
    const isExpired = Date.now() >= exp * 1000;
    // An already logged in user's access token might be stale. To guarantee that the token is
    // valid, we refresh the user's custom data which also refreshes their access token.
    if (isExpired) {
      // To manually refresh the user's expired access token, we refresh their custom data
      await realmApp.currentUser.refreshCustomData();
    }
  }
  return realmApp.currentUser.accessToken;
}


function createApolloClient(realmApp){

    const graphqlUri = `${databaseInfo.baseUrl}/api/client/v2.0/app/${realmApp.id}/graphql`;

    const client = new ApolloClient({
      link: new HttpLink({
        uri: graphqlUri,
        // We define a custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
        // The function intercepts every Apollo HTTP request and adds an Authorization header with a valid
        // access token before sending the request.
        fetch: async (uri, options) => {
          const accessToken = await getValidAccessToken(realmApp);
          options.headers.Authorization = `Bearer ${accessToken}`;
          return fetch(uri, options);
        },
      }),
      cache: new InMemoryCache(),
    });

    return client
}



const RealmAppContext = React.createContext(null);



export function RealmAppProvider({ appId, children }) {
  // Store Realm.App in React state. If appId changes, all children will rerender and use the new realmApp.
  const [realmApp, setRealmApp] = React.useState(createRealmApp(appId));
  const [apolloClient, setApolloClient] = React.useState(createApolloClient(realmApp));

  const [isAdmin, setIsAdmin] = React.useState(false);



  // Store the app's current user in state and wrap the built-in auth functions to modify this state
  const [currentUser, setCurrentUser] = React.useState(realmApp.currentUser);
  const [customData, setCustomData] = React.useState(undefined);
  const [view, setView] = React.useState('');


  React.useEffect(() => {
    let app = createRealmApp(appId)
    setRealmApp(app);

    let aClient = createApolloClient(app)
    setApolloClient(aClient)

    if(currentUser){
      getIsAdmin()

    }
  }, [appId, currentUser]);


  function updateViewLabel(newView){
    setView(newView)
  }


  // Wrap the base logIn function to save the logged in user in state
  const logInEmail = React.useCallback(

    async (credentials) => {

      if(currentUser){
        await currentUser?.logOut();

      }

      await realmApp.logIn(credentials);
      //set provider type as well as
      setCurrentUser(realmApp.currentUser);
      getIsAdmin()
    },
    [realmApp, currentUser]
  );



  async function refreshCustomUserData(){

    if(!realmApp.currentUser) return undefined

    const customUserData =  await realmApp.currentUser.refreshCustomData()

    setCustomData(customUserData)
    return customUserData
  }

  

  async function getIsAdmin(){
    let data = await refreshCustomUserData()
    setIsAdmin(data.is_site_admin)
    return data.is_site_admin
  }


  const fullyRemoveUser = React.useCallback(async () => {

      try{
        await realmApp.removeUser(currentUser);
      }catch(err){
        try{
          await currentUser?.logOut();
        }catch(e){
        }
      }
      setCurrentUser(null);

  }, [realmApp, currentUser]);





  // Override the App's currentUser & logIn properties + include the app-level logout function
  const realmAppContext = React.useMemo(() => {
    return { ...realmApp, apolloClient, currentUser, logInEmail, isAdmin, fullyRemoveUser, refreshCustomUserData, customData, updateViewLabel, view };
  }, [realmApp, apolloClient, currentUser, logInEmail, fullyRemoveUser, isAdmin, refreshCustomUserData, customData, updateViewLabel, view ]);


  return (
    <RealmAppContext.Provider value={realmAppContext}>
      <ApolloProvider client={apolloClient}>
        {children}
      </ApolloProvider>
    </RealmAppContext.Provider>
  );


}

export function useRealmApp() {
  const realmApp = React.useContext(RealmAppContext);
  if (!realmApp) {return undefined}

  return realmApp;
}
