import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import {InputAdornment, Box, Table,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow,TableSortLabel,Toolbar,Button,Typography,Paper,Checkbox,IconButton,Tooltip, TextField,} from '@mui/material';

import BackspaceIcon from '@mui/icons-material/Backspace';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'asc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}




function EnhancedTableHead(props) {
  const {  order, orderBy, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  var {t} = useTranslation();
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: t('name'),
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('emailAddress'),
    },
    {
      id: 'approved',
      numeric: false,
      disablePadding: false,
      label: t('approved'),
    },
    {
      id: 'view',
      numeric: false,
      disablePadding: false,
      label: t('view'),
    }
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            paddingleft='2px'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography variant='body' sx={{fontWeight:'bold'}}>{headCell.label}</Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


function EnhancedTableToolbar(props) {
  const { title, handleSearch } = props;

  const [search, setSearch] = React.useState('');

  const editSearching = (event) => {
    let th = event.target.value
    setSearch(th)
    handleSearch(th)
  }

  const clearSearch = () => {
    setSearch('')
    handleSearch('')
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 }
      }}
    >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          color="primary"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>


        <TextField
          variant='outlined'
          placeholder="Search..."
          multiline={false}
          value={search}
          size='small'
          fullWidth
          onChange={(e) => editSearching(e)}
          InputProps={{
            endAdornment: 
              <InputAdornment position="end">
              <IconButton onClick={() => clearSearch()}><BackspaceIcon/></IconButton>

            </InputAdornment>
          }}

          >
        </TextField>

    </Toolbar>
  );
}



export function ProducerTable({organizations, onSelect, onSearch}) {

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [rows, setRows] = React.useState([])
  const [orgDictionary, setOrgDictionary] = React.useState([])
  const {t} = useTranslation();

  React.useEffect(() => {

    let allOrgs = organizeOrganizations()
    setRows(allOrgs.rows)
    setOrgDictionary(allOrgs.dict)
  }, [organizations]);

  function createData(id, name, email, approved) {
    return {
      id,
      name,
      email,
      approved,
    };
  }


  function organizeOrganizations(){
    var rows = []
    var orgDict = {}

    if(organizations === null || organizations === undefined){
      return {rows:[], dict:{}}
    }
    for(const org of organizations){

      orgDict[org.producer_id] = org
      rows.push(createData(org.producer_id, org.name, org.email, org.validated))
    }

    return {rows:rows, dict:orgDict}
  }


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const isSelected = (id) => selected.indexOf(id) !== -1;

  const viewSelected = (id) =>{
    console.log(orgDictionary)
    onSelect(orgDictionary[id])
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%'}}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar title={t("producers")} handleSearch={(event) => onSearch(event)}/>
        <TableContainer>
          <Table
            sx={{ minWidth: 290 }}
            aria-labelledby="tableTitle"
            size='medium'
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>

              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {

                  const isRowSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      aria-checked={isRowSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isRowSelected}
                    >

                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                      >
                        {row.name}
                      </TableCell>

                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                      >
                        {row.email}
                      </TableCell>

                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                      >
                        {row.approved ? "yes" : "no"}
                      </TableCell>

                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal">

                        <Button variant='contained' onClick={() => viewSelected(row.id)}>{t('view')}</Button>
                      
                      </TableCell>
                      
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Box height={"100px"}/>

    </Box>
  );
}
