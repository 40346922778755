import React, {useEffect, useState} from 'react'

import CssBaseline from "@mui/material/CssBaseline";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { WelcomePage } from "./Welcome/WelcomePage";
import { Admins } from "./Admins/Admins";
import { TopNavigationBar } from "./common/TopNavigationBar";
import { ResetPage } from "./Reset/ResetPage";
import { SendReset } from "./Reset/SendReset";
import { AdminAccess } from './AdminAccess/AdminsAccess';
import { Home } from "./Home/Home";
import { Unauthorized } from './Unauthorized/Unauthorized';

import '../i18n/config';

import { RealmAppProvider, useRealmApp } from "./RealmApp";
import { MyThemeProvider } from "./Theme";

import databaseInfo from "../realm.json";
import "./App.css";
import { ProducerApproval } from './ProducerApproval/ProducerApproval';
import { Certifications } from './Certifications/Certifications';



export default function AppWithRealm() {

  return (
    <MyThemeProvider>
      <CssBaseline/>
        <RealmAppProvider appId={databaseInfo.appId}>
            <App />
        </RealmAppProvider>
    </MyThemeProvider>
  );
}


function App() {

  const { currentUser, isAdmin } = useRealmApp();

  const [showBars, setShowBars] = useState(false)

  var value  = "home"

  


  React.useEffect(() => {

      if(window.location.pathname === "/" || window.location.pathname === "/unauthorized" || window.location.pathname === "/splash" || window.location.pathname === "/login" || window.location.pathname === "/reset" || window.location.pathname === "/sendreset"){
        setShowBars(false)
      }else{
        setShowBars(true)
      }
      

  }, [currentUser, window.location.pathname, isAdmin])



  return (
    <div className="App">

      <Router>

        {(isAdmin && showBars) && <TopNavigationBar /> }

        <Routes>

            <Route path="/home" element={isAdmin ? <Home/> : <Unauthorized/>}/>
            <Route path="/admins" element={isAdmin ? <Admins/> : <Unauthorized/>}/>
            <Route path="/approval" element={isAdmin ? <ProducerApproval/> : <Unauthorized/>}/>
            <Route path="/access" element={isAdmin ? <AdminAccess/> : <Unauthorized/>}/>
            <Route path="/certifications/:id" element={isAdmin ? <Certifications/> : <Unauthorized/>}/>
            <Route path="/unauthorized" element={<Unauthorized/>}/>
            <Route path="/login" element={<WelcomePage/>}/>
            <Route path="/reset" element={<ResetPage/>}/>
            <Route path="/sendreset" element={<SendReset/>}/>
            <Route path="/" element={<WelcomePage/>}/>

        </Routes>


      </Router>


    </div>
  );
}
