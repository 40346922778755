import React from "react";
import { ApolloClient, gql, HttpLink, InMemoryCache, useMutation } from "@apollo/client";
import { useRealmApp } from "../components/RealmApp";
import databaseInfo from "../realm.json";
import { BSON } from "realm-web";
import { GET_PRODUCER_CERTIFICATIONS, GET_CERTIFICATIONS_BY_APPROVAL, GET_ALL_CERTIFICATIONS, GET_ONE_SITE_INFORMATION,ADD_TO_MEDIA_GALLERY,DELETE_ONE_MEDIA_GALLERY,GET_MEDIA_GALLERY,GET_ONE_PRODUCER_PROFILE,
GET_ONE_PRODUCT,GET_PRODUCER_RECIPES,
  GET_PRODUCER_ACCESS, UPDATE_PRODUCER_PROFILE, GET_ALL_TEMPORARY_ADMINS, UPDATE_ONE_CERTIFICATION,  GET_ALL_PRODUCER_PROFILES, GET_PRODUCER_INVENTORIES, GET_PRIVATE_PROFILE, GET_PRODUCER_STORIES, ADD_TO_TEMPORARY_ADMINS } from "./fragments";



const DEV_DATABASE = "navore-dev"
const PROD_DATABASE = "navore"

//Currently In DEVELOPEMENT
const DATABASE = PROD_DATABASE




export function useNavore() {

  // Get a graphql client and set up a list of todos in state
  const realmApp = useRealmApp();
  const graphql = realmApp.apolloClient

  const [privateProfile, setPrivateProfile] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  


  // Fetch all todos on load and whenever our graphql client changes (i.e. either the current user OR App ID changes)
  React.useEffect(() => {

    if(realmApp.currentUser != null){
      fetchProfiles()
    }

  }, [realmApp.currentUser, graphql]);

  
  // Fetch all todos on load and whenever our apolloClient client changes (i.e. either the current user OR App ID changes)
  const fetchProfiles = async() =>{

    await getPrivateProfile(realmApp.currentUser.id)
    
    setLoading(false)
  }








/************************************************************
* CACHE MODIFYING QUERY's
*********************************************************************/



//USE for on create productInventory
const createTemporaryAdmin = (cache, { data }) => {
  cache.modify({ 
    fields: {
      temporaryAdmins(exisitingTemporaryAdmins = []) {
        const newAdmin = data.insertOneTemporaryAdmin;
        cache.writeQuery({
          query: GET_ALL_TEMPORARY_ADMINS,
          data: { newAdmin, ...exisitingTemporaryAdmins }
        });
      }
    }
  })
};

//USE for on create productInventory
const createMedia = (cache, { data }) => {
  cache.modify({ 
    fields: {
      producerLocations(exisitingMediaGallery = []) {
        const newMedia = data.insertOneMediaGallery;
        cache.writeQuery({
          query: GET_MEDIA_GALLERY,
          data: { newMedia, ...exisitingMediaGallery }
        });
      }
    }
  })
};




//USE for on delete productInventory
const removeMedia = (cache, { data }) => {
  cache.modify({ 
    fields: {
      producerLocations(exisitingMediaGallery = []) {
        const deletingMedia = data.deleteOneMediaGallery;

        var filtered = exisitingMediaGallery.filter(el => !el.__ref.includes(deletingMedia._id.toString()) ); 

        cache.writeQuery({
          query: GET_MEDIA_GALLERY,
          data: { filtered }
        });
      }
    }
  })
};


/********************************
*  END -- CACHE MODIFYING QUERY's
*********************************/


  /************************************************************
  CACHE MODIFYING MUTATION's
  *********************************************************************/

  // const [addMediaGallery, { addMediaCalled, addMediaError }] = useMutation(ADD_TO_MEDIA_GALLERY,{update:createMedia});
  // const [deleteMediaGallery, { deleteMediaCalled, deleteMediaError }] = useMutation(DELETE_ONE_MEDIA_GALLERY,{update:removeMedia});

  const [addTemporaryAdmin, { addTempAdminCalled, addTempAdminError }] = useMutation(ADD_TO_TEMPORARY_ADMINS,{update:createTemporaryAdmin});
  /**************************************
  *  END -- CACHE MODIFYING MUTATIONS's
  * ***********************************/


  async function getTemporaryAdmins(id, producerId){

    console.log("ID: ", id, producerId)

    let response = await graphql.query({ query:GET_ALL_TEMPORARY_ADMINS, variables:{uid:id, producer_id:producerId, expired:false} })
   
    let access = response.data.temporaryAdmin
    
    return access
  }


  async function getAllTemporaryAdmins(){

    let response = await graphql.query({ query:GET_ALL_TEMPORARY_ADMINS, variables:{expired:false}})
    let access = response.data.temporaryAdmins
    console.log(access)

    return access
  }


  async function addToTemporaryAdmins(data){

    try {
  
      let result = await addTemporaryAdmin({variables:{ data: data }})

      let newM = result.data.insertOneTemporaryAdmin
      console.log(newM)

      return newM
      } catch (err) {
        console.error(err);
        return undefined
      }

    // let response = await graphql.query({ query:ADD_TO_TEMPORARY_ADMINS, variables:data })
    // let access = response.data.temporaryAdmins

  }

  async function getPrivateProfile(id){

    let response = await graphql.query({ query:GET_PRIVATE_PROFILE, variables:{uid:id} })
    let pri = response.data.privateProfile

    setPrivateProfile(pri);
  }


  async function getProducerInventory(producer_id){
    let response = await graphql.query({ query:GET_PRODUCER_INVENTORIES, variables:{producer_id:producer_id} })
    let pub = response.data.producerInventories

    return pub
  }


  async function getProducerStories(producer_id){
    let response = await graphql.query({ query:GET_PRODUCER_STORIES, variables:{producer_id:producer_id} })
    let pub = response.data.producerStories

    return pub
  }

  async function getProducerRecipes(producer_id){
    let response = await graphql.query({ query:GET_PRODUCER_RECIPES, variables:{producer_id:producer_id} })
    let pub = response.data.recipes

    return pub
  }


  async function getAdmins(){
    let response = await graphql.query({ query:GET_ONE_SITE_INFORMATION, variables:{unique_name:"site_admins"} })
    let pub = response.data.siteInformation

    return pub
  }


  async function getAllCertifications(){
    let response = await graphql.query({ query:GET_ALL_CERTIFICATIONS })
    let certs = response.data.producerCertifications

    return certs
  }

  

  async function getCertificationsByApproval(approved){
    let response = await graphql.query({ query:GET_CERTIFICATIONS_BY_APPROVAL, variables:{approved:approved} })

    let certs = response.data.producerCertifications

    return certs
  }


  async function getCertificationsByProducer(producer_id){
    let response = await graphql.query({ query:GET_PRODUCER_CERTIFICATIONS, variables:{producer_id:producer_id} })

    let certs = response.data.producerCertifications

    return certs
  }



  async function updateProducerCertification(data, id){

    if (!realmApp.currentUser) return;
    let objID = BSON.ObjectId(id)
    try {
        let result = await graphql.mutate({
          mutation: UPDATE_ONE_CERTIFICATION,
          variables: { data: data, _id:objID },
        });


        return result
      } catch (err) {
        console.error(err);
        return undefined
      }
  }

  const updateProducerProfile = async (data, producer_id) =>{

    if (!realmApp.currentUser) return;

    try {
      let result = await graphql.mutate({
          mutation: UPDATE_PRODUCER_PROFILE,
          variables: { data: data, producer_id:producer_id },
        });

        let profile = result.data.updateOneProducerProfile
        return profile

      } catch (err) {
        console.error(err);
        return undefined
      }
  }

  async function getTopProducerPublicProfiles(){

    let response = await graphql.query({ query:GET_ALL_PRODUCER_PROFILES })
    let pub = response.data

    if(pub == null)return []
    return pub.producerProfiles
  }



  async function getNearbyProducerNews(coordinates, distance){

    let type = 'Point'

    const mdb = realmApp.currentUser.mongoClient(databaseInfo.dataSourceName);
    const producer_news = mdb.db(databaseInfo.navoreDatabase).collection("ProducerNews")
    try {
      let result = await producer_news.find(
        {
          location:
            { 
              $nearSphere :
               {
                 $geometry: { type: type,  coordinates: coordinates },
                 $maxDistance: Number(distance)
               }
            }
        }
     )
      return result
    } catch (err) {
        console.error(err);
        return undefined
    }


  }

  function getProducerIdsFromList(producers){


    let ids = []
    for(const producer of producers){
      if(!ids.includes(producer.producer_id)){
        ids.push(producer.producer_id)
      }
    }

    return ids
  }


  // async function queryAllProducersDelivery(type){

  //   let response
  //   if(type === 'delivery'){
  //     response = await graphql.query({ query:QUERY_PRODUCER_PROFILES_DELIVERY, variables:{delivery:true} })

  //   }else if(type === 'pickup'){
  //     response = await graphql.query({ query:QUERY_PRODUCER_PROFILES_PICKUP, variables:{pickup:true} })
  //   }

  //   let pub = response.data.producerProfiles

  //   if(pub == null)return []
  //   return pub

  // }

  async function getTopProducerStories(){

    let response = await graphql.query({ query:GET_PRODUCER_STORIES })
    let pub = response.data.producerNews
    return pub
  }
  

  async function getProducerAccess(id){

    let response = await graphql.query({ query:GET_PRODUCER_ACCESS, variables:{producer_id:id} })

    let access = response.data.producerAccess

    return access
  }



  async function getProducerPublicProfile(id){

    let objId = new BSON.ObjectId(id)
    let response = await graphql.query({ query:GET_ONE_PRODUCER_PROFILE, variables:{_id:objId} })
    let pub = response.data.producerProfile
    
    return pub
  }


  async function getAllProducers(){

    let response = await graphql.query({ query:GET_ALL_PRODUCER_PROFILES, fetchPolicy:'network-only'})
    let pub = response.data.producerProfiles

    if(!pub ){return []}

    return pub

  }

  // async function getAllProduce(){

  //   let response = await graphql.query({ query:GET_ALL_PRODUCES})
  //   let pub = response.data

  //   if(!pub ){return []}

  //   return pub

  // }

  // async function getProduceForType(type){

  //   let response = await graphql.query({ query:GET_PRODUCE_BY_TYPE, variables:{type:type} })
  //   let pub = response.data

  //   return pub

  // }

  async function getMediaGallery(producer_id){
    let response = await graphql.query({ query:GET_MEDIA_GALLERY, variables:{producer_id:producer_id} })
    let pub = response.data.mediaGallery

    return pub
  }

  async function getProducerRecipes(producer_id){
    let response = await graphql.query({ query:GET_PRODUCER_RECIPES, variables:{producer_id:producer_id} })
    let pub = response.data.recipes

    return pub
  }

  // async function getAllOrders(id){
    
  //   let response = await graphql.query({ query:GET_ALL_ORDERS_FOR_ID, variables:{uid:id} })
  //   let pub = response.data
  //   return pub
  // }

  // async function getAllOpenOrders(id){

  //   const query = gql`
  //     query FetchAllOpenOrders($order_completed: Boolean!, $producer_id:String!) {
  //       orders ( query: {  order_completed: $order_completed , producer_id: $producer_id } ){
  //         _id
  //         producer_od
  //         name
  //         cover_image
  //         images
  //         recipes
  //         latitude
  //         longitude
  //       }
  //     }
  //   `;
  //   let response = await graphql.query({ query:query, variables:{order_completed:false, producer_id:id} })
  //   let pub = response.data
  //   return pub
  // }



  // useWatch(privateProfileCollection, {
  //   onUpdate: (change) => {
  //     console.log("CHANGE to PRIVATE PROFILE", change)
  //     if(loading){
  //       setPrivateProfile(privateProfile)
  //     }else{
  //       setPrivateProfile(change.fullDocument)
  //     }
  //   }
  // });

  // async function setInventoryWatcher(){
  //     for await (const change of producerInventoryCollection.watch ({
  //       filter: {
  //         operationType: "update",
  //         "fullDocument.status": "public",
  //       },
  //     })) {
  //       // The change event will always represent a newly inserted perennial
  //       const { documentKey, fullDocument } = change;
  //       console.log(`new document: ${documentKey}`, fullDocument);
  //       break; // Exit async iterator
  //     }

  // }


  // useWatch(producerInventoryCollection, {
  //   onUpdate: (change) => {
  //     console.log("CHANGE to Producer Inventory", change)
  //     if(loading){
  //       setCart(privateProfile)
  //     }else{
  //       setCart(change.fullDocument)
  //     }
  //   }
  // });



  async function getProduct(id){

    let objectid = BSON.ObjectID(id)
      
    let response = await graphql.query({ query:GET_ONE_PRODUCT, variables:{id:objectid} })
    let product = response.data.producerInventory

    return product
  }

  


  return {
    loading,
    getAdmins,
    getAllCertifications,
    updateProducerCertification,
    addToTemporaryAdmins,
    getTemporaryAdmins,
    getAllTemporaryAdmins,
    getCertificationsByApproval,
    getCertificationsByProducer,
    updateProducerProfile,
    privateProfile,
    getProduct,
    getAllProducers,
    getProducerRecipes,
    setPrivateProfile,
    getProducerStories,
    getProducerAccess,
    getProducerPublicProfile,
    getTopProducerPublicProfiles,
    getNearbyProducerNews,
    getProducerInventory,
    getMediaGallery

  };
}
