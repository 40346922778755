import React from "react";
import * as Realm from "realm-web";
import { useRealmApp } from "../RealmApp";


import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ButtonGroup from "@mui/material/ButtonGroup";
import Fab from "@mui/material/Fab";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";


import Skeleton from '@mui/material/Skeleton';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {  MoreInfoItem } from "../common/MoreInfo";
import { toggleBoolean } from "../../utils";
import { useErrorAlert } from "../../hooks/useErrorAlert";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export function WelcomePage() {

  const realmApp = useRealmApp();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  // Track whether the user is logging in or signing up for a new account


  React.useEffect(() =>{

    if (realmApp.currentUser) {
      navigate("/home", { replace: true });
    }

  }, [realmApp.currentUser])



  // Authentication errors
  const noErrors = {
    email: null,
    password: null,
    other: null,
  };

  const [error, setError] = React.useState(noErrors);
  const clearErrors = () => setError(noErrors);
  const NonAuthErrorAlert = useErrorAlert({
    error: error.other,
    clearError: () => {
      setError((prevError) => ({ ...prevError, other: null }));
    },
  });
  // Manage password visibility
  const [showPassword, setShowPassword] = React.useState(false);
  const toggleShowPassword = () => setShowPassword(toggleBoolean);


  const onFormSubmit = async ({ email, password }) => {
    clearErrors();
    try {
        await realmApp.logInEmail(Realm.Credentials.emailPassword(email, password));
    } catch (err) {
      handleAuthenticationError(err, setError);
    }
  };

  const toResetPassword = () =>{
    navigate("/sendreset", {replace:true})
  }



  return (

    <div className="login-container">

        <div className='login-top-bar'>

            <div className="login-top-display">

              <Typography align="center" variant="h5" color="secondary">
                  {t("signIn").toUpperCase()}
              </Typography>
            </div>
        </div>

        <div className="login-content">

        <Container maxWidth='sm'>
            <Paper style={{
                padding: 8,
              }}>

              <form
                className="auth-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  const formData = new FormData(e.target);
                  const { email, password } = Object.fromEntries(formData.entries());
                  onFormSubmit({ email, password });
                }}
              >

              <NonAuthErrorAlert />


              <TextField
                  id="input-email"
                  name="email"
                  margin="dense"
                  size='small'
                  label={t("emailAddress")}
                  variant="outlined"
                  error={Boolean(error.email)}
                  helperText={error.email ?? ""}
              />

              <TextField
                  id="input-password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  size='small'
                  label={t("createPassword")}
                  margin="dense"
                  variant="outlined"
                  error={Boolean(error.password)}
                  helperText={error.password ?? ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleShowPassword}
                          onMouseDown={(e) => {
                            e.preventDefault();
                          }}
                        >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
              />

              <Fab
                variant="extended" type="submit" color="primary">
                  {t("logIn")}
              </Fab>

              <div className='standard-vertical-spacing'/>

              <Typography align="center" variant="subtitle2">
                  {t("submissionTermsAndCondition")}
              </Typography>



            </form>

            </Paper>

          </Container>

          </div>
    </div>
  );
}



function handleAuthenticationError(err, setError) {
  const handleUnknownError = () => {
    setError((prevError) => ({
      ...prevError,
      other: "Something went wrong. Try again in a little bit.",
    }));
    console.warn(
      "Something went wrong with a Realm login or signup request. See the following error for details."
    );
    console.error(err);
  };
  if (err instanceof Realm.MongoDBRealmError) {
    const { error, statusCode } = err;
    const errorType = error || statusCode;
    switch (errorType) {
      case "invalid username":
        setError((prevError) => ({
          ...prevError,
          email: "Invalid email address.",
        }));
        break;
      case "invalid username/password":
      case "invalid password":
      case 401:
        setError((prevError) => ({
          ...prevError,
          password: "Incorrect password.",
        }));
        break;
      case "name already in use":
      case 409:
        setError((prevError) => ({
          ...prevError,
          email: "Email is already registered.",
        }));
        break;
      case "password must be between 6 and 128 characters":
      case 400:
        setError((prevError) => ({
          ...prevError,
          password: "Password must be between 6 and 128 characters.",
        }));
        break;
      default:
        handleUnknownError();
        break;
    }
  } else {
    handleUnknownError();
  }
}

