import { gql } from '@apollo/client';


export const CORE_MEETING_FORM_REQUEST = gql`
  fragment CoreMeetingFormRequest on MeetingFormRequest {
    _id
    full_name
    email
    type
    phone_number
    business_name
    county
    product_categories
    contact_method
    contact_days
    start_time
    end_time
    start_time_format
    end_time_format
    hear_about_us
    date
  }
`;


export const CORE_PRODUCER_WHOLESALE_GROUP = gql`
  fragment CoreProducerWholesaleGroup on ProducerWholesaleGroup {
    _id
    producer_id
    image_url
    name
    description
    members 
  }
`;


export const CORE_MESSAGE = gql`

fragment CoreMessage on Inbox {
    _id
    timestamp
    recipient_id
    sender_id
    sender_type
    receiver_type
    recipient_avatar
    sender_avatar
    body
    title
    is_seen
}
`;

export const CORE_NOTIFICATION = gql`

fragment CoreNotification on Notification {
    _id
    timestamp
    recipient_id
    sender_id
    primary_id
    alternate_id
    type
    body
    title
    is_seen
    sender_name
}
`;

export const CORE_SCHEDULED_NOTIFICATION = gql`

fragment CoreScheduledNotification on ScheduledNotification {
    _id
    timestamp
    send_at
    recipient_id
    sender_id
    primary_id
    alternate_id
    type
    body
    title
    is_seen
    sender_name
}
`;


export const CORE_MEDIA_GALLERY = gql`
  fragment CoreMediaGallery on MediaGallery {
    _id
    producer_id
    customer_submitted
    customer_id
    customer_name
    url
    title
    description
    status
    type
    timestamp
  }
`;

export const CORE_PRODUCER_INVENTORY = gql`
  fragment CoreProducerInventory on ProducerInventory {
      _id
      producer_id
      nickname
      description
      product_variety
      product_type
      group_availability
      product_category
      available_general_public
      seasonality
      weight_unit
      notify_on_sell_out
      notify_thresholds {type value}
      internal_tags
      track_inventory
      unlimited_inventory
      inventory_by
      charge_by
      remaining_inventory
      publish_status
      availability_status
      packs { pack_id name price unit weight_per_unit available_general_public weight_unit adjustments  { group_name group_id adjustment_type value use_price_adjust}  }
      attributes { id name status description }
      thumbnail_image
      photos
  }
`;

export const CORE_INBOX = gql`

fragment CoreInbox on Inbox {
    _id
    body
    recipient_id
    sender_id
    timestamp
    title
    type
}`;


export const CORE_CANCELLATION = gql`
fragment CoreCancellation on Cancellation {
    _id
    cancellation_reason
    producer_id
    timestamp
    subscription_id
}
`;


export const CORE_PRODUCER_PAYMENT_SETTING = gql`

fragment CorePaymentSetting on PaymentSetting {
    _id
    payment_methods {nickname description token token_sf internal_id type }
    preferred_payment_method
    subscription_plan
    producer_id 
    type
    status
    billing_date
    restart_date
    is_paused
    auto_renewal
    amount
}
`;


export const CORE_PUBLIC_PROFILE = gql`
  fragment CorePublicProfile on PublicProfile {
    _id
    uid
    full_name
    first_name
    last_name 
    email
    wholesale_id
    profile_image_url
  }
`;


export const CORE_PAYMENT_TRANSACTION = gql`

fragment CorePaymentTransaction on PaymentTransaction {
    _id
    transaction_type
    primary_id
    transaction_ref
    producer_id
    customer_id
    amount
    date
}
`;


export const CORE_PRODUCER_CUSTOMER = gql`
  ${CORE_PUBLIC_PROFILE}
  fragment CoreProducerCustomer on ProducerCustomers {
    _id
    producer_id
    customers {...CorePublicProfile}
  }
`;


export const CORE_PAYMENTS = gql`
fragment CoreCurrentSubscription on CurrentSubscriptions {
    _id
    auto_renewal
    expire_date
    producer_id {...CorePaymentSetting}
    type
    is_paused
    restart_date
}
`;


export const CORE_CONTACT_PRODUCER = gql`

fragment CoreContactProducer on ContactProducer {
    _id
    comment
    customer_id
    producer_id
    product_id
    order_id
}
`;


export const CORE_ORDER_ITEM = gql`
  ${CORE_PRODUCER_INVENTORY}

fragment CoreOrderItem on OrderItem {
    _id
    image_url
    inventory_change_decision_required
    decision
    inventory_id {...CoreProducerInventory}
    name
    order_id
    producer_id
    customer_id
    is_custom
    status
    weight_unit
    pack { pack_id price weight_per_unit ordered_quantity fulfilled_quantity allocated_units harvest_allocation name unit total weight_unit }
}
`;


export const CORE_SITE_INFORMATION = gql`
${CORE_PUBLIC_PROFILE}
fragment CoreSiteInformation on SiteInformation {
    _id
    unique_name
    _partition
    members {...CorePublicProfile}
    name
}
`;


export const CORE_PRODUCER_PLAN_NO_PROFILE = gql`
  fragment CoreProducerPlanNoProfile on ProducerPlan {
    _id
    producer_id
    name
    plan_type
    location_description
    location { coordinates, type }
    street_address
    full_address
    city
    state
    zip
    stand_photos
    future_days_limit
    order_cutoff_days
    order_cutoff_time
    order_cutoff_time_format
    window_end_time_format
    is_own_farm
    window_start_time_format
    cancellation_cutoff_hours
    enable_free_delivery_threshold
    recurring_schedule { start_date end_date repeat_value repeat_period days_of_week dates}
    minimum_order_value
    delivery_fee
    free_delivery_available
    minimum_value_for_free_delivery
    delivery_zone_zipcodes
    delivery_zone_type
    status
    window_start_time
    window_end_time
    wholesale_groups { _id members name description }
    available_general_public
  }
`;


export const CORE_PRODUCER_PROFILE = gql`
  ${CORE_PRODUCER_INVENTORY}
  fragment CoreProducerProfile on ProducerProfile {
    _id
    producer_id
    storefront_paused
    product_categories
    name
    email
    header_image
    validated
    url
    rating
    vehicles
    street_address
    state
    total_reviews
    profile_image
    about_products_image
    producer_story_image
    location { coordinates, type }
    about_produce
    producer_story
    analytics_period
    timezone
    home_page_analytics
    thank_you_response
    all_inventory {...CoreProducerInventory}
    twitter_profile_url
    youtube_profile_url
    zip
    city
    full_address
    third_party_website
    facebook_profile_url
    instagram_profile_url
    linkedin_profile_url
  }
`;



export const CORE_TEMPORARY_PRODUCER = gql`
  fragment CoreTemporaryProducer on TemporaryProducer {
    _id
    uid
    product_categories
    name
    email
    producer_story
    city
    
  }
`;


export const CORE_METRIC = gql`
fragment CoreMetric on Metric {
    producer_id
    resource_id
    resource
    category
    value
    timestamp
}

`;



export const CORE_PRODUCER_PROFILE_NO_INV = gql`
  fragment CoreProducerProfileNoInv on ProducerProfile {
    _id
    producer_id
    storefront_paused
    product_categories
    name
    email
    header_image
    validated
    url
    rating
    street_address
    state
    total_reviews
    profile_image
    producer_story_image
    location { coordinates, type }
    about_produce
    about_products_image
    producer_story
    analytics_period
    timezone
    home_page_analytics
    thank_you_response
    twitter_profile_url
    youtube_profile_url
    zip
    city
    full_address
    third_party_website
    facebook_profile_url
    instagram_profile_url
    linkedin_profile_url
  }
`;



export const CORE_PRODUCER_PLAN = gql`
  ${CORE_PRODUCER_PROFILE}
  fragment CoreProducerPlan on ProducerPlan {
    _id
    producer_id
    producer_profile {...CoreProducerProfile}
    name
    plan_type
    location_description
    enable_free_delivery_threshold
    location { coordinates, type }
    street_address
    full_address
    city
    state
    zip
    stand_photos
    order_cutoff_days
    order_cutoff_time
    order_cutoff_time_format
    future_days_limit
    is_own_farm
    window_end_time_format
    window_start_time_format
    cancellation_cutoff_hours
    recurring_schedule { start_date end_date repeat_value repeat_period days_of_week dates}
    minimum_order_value
    delivery_fee
    free_delivery_available
    minimum_value_for_free_delivery
    delivery_zone_zipcodes
    delivery_zone_type
    status
    window_start_time
    window_end_time
    wholesale_groups { _id members name description }
    available_general_public
  }
`;


export const CORE_REFUND = gql`
fragment CoreRefund on Refund {
    _id
    status
    description
    order_id
    producer_id
    photos
    confirmation_notes
    rejection_reason
    total_cost
    accepted_items
    reason
    date_requested
    accepted_funds
    items 
    subject
}
`;


export const CORE_ORDER = gql`
${CORE_ORDER_ITEM}
${CORE_PRODUCER_PLAN_NO_PROFILE}
${CORE_REFUND}

${CORE_PUBLIC_PROFILE}

fragment CoreOrder on Order {
    _id
    producer_id
    cancelled
    comments
    wholesale_order
    change_count
    subtotal_cost
    total_cost
    delivery_fee
    navore_fee
    total_labels
    total_boxes
    tip
    customer_notes
    internal_notes
    wholesale_groups
    cancellation_reason
    cancellation_notes
    customer_profile { ...CorePublicProfile }
    customer_id
    delivery_address
    billing_address
    estimated_timestamp
    delivery_status
    is_delivery
    order_completed_timestamp
    order_placed_timestamp
    due_date
    order_status
    thank_you_note
    payment_status
    payment_id 
    payment_info {internal_id token token_sf description}
    delivery_status
    feedback { subject description photos }
    plan { ...CoreProducerPlanNoProfile }
    refund_request { ...CoreRefund }
    order_items { ...CoreOrderItem }
    missing_items
    missing_item_description
    damaged_items
    damaged_item_description
    recurring { frequency start_date}
}
`;


export const CORE_RECURRING_ORDER = gql`
${CORE_ORDER_ITEM}
${CORE_PRODUCER_PLAN_NO_PROFILE}
${CORE_REFUND}
${CORE_PAYMENT_TRANSACTION}
${CORE_PUBLIC_PROFILE}

fragment CoreRecurringOrder on RecurringOrder {

    _id
    producer_id
    cancelled
    comments
    wholesale_order
    change_count
    subtotal_cost
    total_cost
    delivery_fee
    navore_fee
    tip
    customer_profile { ...CorePublicProfile }
    customer_id 
    delivery_address
    billing_address
    estimated_timestamp
    delivery_status
    is_delivery
    order_completed_timestamp
    order_placed_timestamp
    due_date
    order_status
    thank_you_note
    payment_status
    payment_id 
    payment_info {internal_id token token_sf description}
    delivery_status
    plan { ...CoreProducerPlanNoProfile }
    order_items { ...CoreOrderItem }
    recurring { frequency start_date}
}
`;



export const CORE_DAILY_HARVEST_LIST = gql`
  fragment CoreDailyHarvestList on DailyHarvestList {
      _id
      producer_id 
      status
      date
      all_orders
      product_id
      collected
      remaining
      total
      details
      assignee {name uid}
      notes 
      status 
      unit 
      name
      archived
  }
`;

export const CORE_DAILY_PACKING_LIST = gql`
  fragment CoreDailyPackingList on DailyPackingList {
      _id
      producer_id 
      status
      date
      order_id
      total_boxes
      details
      assignee {name uid}
      notes 
      status 
      name
      archived

  }
`;

export const CORE_DAILY_PREPACKING_LIST = gql`
  fragment CoreDailyPrepackingList on DailyPrepackingList {
      _id
      producer_id 
      status
      notes
      date
      all_orders
      product_id
      details
      collected
      remaining
      total
      assignee {name uid}
      unit 
      name
      archived

  }
`;

export const CORE_DELIVERY_ROUTE = gql`
  ${CORE_ORDER}
  fragment CoreDeliveryRoute on Delivery {
      _id
      producer_id 
      date
      route_notes
      total_routes
      route_vehicles
      route_statuses
      loading_statuses
      archived
      route_assignees {uid name}
      loading_assignees {route_number users }
      all_orders {...CoreOrder}
      deliveries {order_id plan_id address coordinates {lat lng} route_number completed status loaded}
  }
`;

// export const CORE_DELIVERY_LOADING = gql`
//   ${CORE_ORDER}
//   fragment CoreDeliveryLoading on DeliveryLoading {
//       _id
//       producer_id 
//       date
//       total_trucks
//       all_orders {...CoreOrder}
//       truck_assignees
//       items {order_id address truck_number loaded status}
//   }
// `;

export const CORE_LIST_GROUPS = gql`
  ${CORE_PRODUCER_INVENTORY}
  fragment CoreListGroup on ListGroup {
      _id
      producer_id 
      users {id name}
      default_days
      type
      products { ...CoreProducerInventory }
      name
  }
`;

export const CORE_LIST_MANAGER = gql`
  fragment CoreListsManager on ListsManager {
      _id
      producer_id 
      default_harvest_days
      default_prepacking_days
      default_packing_days
      use_harvest_manager
      show_packing_boxes
      use_packing_manager
      use_prepacking_manager
      show_packing_boxes
      harvest_user { name uid }
      prepacking_user { name uid }
      packing_user { name uid }
      prepacking_group
      harvest_group
      
  }
`;

export const CORE_PRODUCER_CERTIFICATION = gql`
  fragment CoreProducerCertification on ProducerCertification {
      _id
      producer_id
      type
      docs
      name
      approved
      date_created
      date_updated
      notes
      messages { created sender_id body }
  }
`;


export const CORE_PRODUCER_ACCESS = gql`
${CORE_PUBLIC_PROFILE}
  fragment CoreProducerAccess on ProducerAccess {
      _id
      producer_id
      owners
      associates {...CorePublicProfile}
      wholesale_memberships { group_id uid }
      temporary_full_admin
      admin_account
      order_settings
      reports_history
      wholesale_orders
      charge_orders
      refunds_and_cancellations
      log_order
      edit_orders
      edit_products
      storefront_settings
      dailylists_completion
      dailylist_management
      view_orders
      view_products
      view_customer_list
      delivery_and_pickup
      regulations
  }
`;







export const CORE_UNCLAIMED_PRODUCER_ACCESS = gql`
fragment CoreUnclaimedProducerAccess on UnclaimedProducerAccess {
    _id
    uid
    producer_id
    email
    permissions
    name
    claimed
    wholesale_customer
}
`;


export const CORE_PRODUCER_STORY = gql`


  fragment CoreProducerStory on ProducerStory {
    _id
    producer_id
    tags
    post
    review_id
    name
    avatar
    location_name
    image_urls
    timestamp
    status
  }
`;

export const CORE_PRODUCT_CATEGORIES = gql`
  fragment CoreProductCategories on ProductCategory{
    _id
    type
    category
  }
`;




export const CORE_PRIVATE_PROFILE = gql`
${CORE_PUBLIC_PROFILE}
fragment CorePrivateProfile on PrivateProfile {
    _id
    uid
    language
    is_site_admin
    phone_number
    public_profile { ...CorePublicProfile}
    producer_ids
    notifications_token
    favorite_recipes
    favorite_producers 
    favorite_products 
    addresses { id notes state city full_address zip street_address nickname }
    payment_methods {type  token token_sf description nickname internal_id}
    preferred_payment_method
    preferred_address
    notification_settings { order_cancelled_by_producer }
    wholesale_id
    exclusive_deals_and_promotions
    unsubscribe_from_emails
    permissions { 
      owners
      temporary_full_admin
      wholesale_memberships
      admin_account
      order_settings
      reports_history
      wholesale_orders
      charge_orders
      refunds_and_cancellations
      log_order
      edit_orders
      edit_products
      storefront_settings
      dailylists_completion
      dailylist_management
      view_orders
      view_products
      view_customer_list
      delivery_and_pickup
      regulations
    }
}
`;



export const CORE_RECIPE = gql`
${CORE_PRODUCER_INVENTORY}
fragment CoreRecipe on Recipe {
    _id
    views
    producer_id
    ingredients{ amount name product_id }
    producer_ingredients { ...CoreProducerInventory}
    difficulty
    name
    time_hours
    time_minutes
    steps
    status
    images 
    thumbnail_image
    serves_number_people
}
`;


export const CORE_RECIPE_WITH_PROFILE = gql`
${CORE_PRODUCER_PROFILE_NO_INV}
${CORE_PRODUCER_INVENTORY}
fragment CoreRecipeWithProfile on Recipe {
    _id
    views
    producer_id
    producer_profile { ...CoreProducerProfileNoInv }
    ingredients{ amount name product_id }
    producer_ingredients { ...CoreProducerInventory}
    difficulty
    name
    time_hours
    time_minutes
    steps
    status
    images
    thumbnail_image
    serves_number_people
}
`;



export const CORE_REVIEW = gql`
fragment CoreReview on Review {
    _id
    customer_id 
    images
    order_id
    producer_id
    rating
    location_name
    reposted_to_news
    avatar
    name
    body
    timestamp
}
`;

export const CORE_CART = gql`

${CORE_PRODUCER_INVENTORY}
${CORE_PRODUCER_PROFILE_NO_INV}
${CORE_PRODUCER_PLAN_NO_PROFILE}

fragment CoreCart on Cart {
    _id
    uid
    total
    producer_profile {...CoreProducerProfileNoInv}
    plan {...CoreProducerPlanNoProfile}
    inventory { ...CoreProducerInventory}
    items { pack_id ordered_quantity product_id unit weight_unit name weight_per_unit price total}
    producer_id
    popular_products { product_id ordered_quantity }
}
`;

export const CORE_ARCHIVED_LIST = gql`

fragment CoreArchivedList on ArchivedList {
    _id
    all_harvest_items { product_id name ordered_quantity status unit}
    all_pack_items { product_id name ordered_quantity status unit}
    all_prepack_items { product_id name ordered_quantity status unit}
    harvest_progress {product_id progression status unit}
    prepacking_progress {product_id progression status unit}
    packing_progress {product_id progression status unit}
    date
    producer_id
}
`;


export const CORE_CUSTOMER_IMAGE = gql`
${CORE_PRODUCER_PROFILE}
${CORE_PRODUCER_INVENTORY}

fragment CoreCustomerImage on CustomerImage {
    _id
    images
    product_id {...CoreProducerInventory}
    producer_id
    customer_profile {...CorePublicProfile}
    customer_id 
}
`;


export const CORE_PRODUCER_WHOLESALE_CUSTOMER = gql`
  fragment CoreProducerWholesaleCustomer on ProducerWholesaleCustomer {
    _id
    producer_id
    name
    customer_id
    language
    groups
    image_url
    phone
    email
    pickup
    delivery
    communication_preference
    payment_methods {type nickname internal_id  token token_sf description} 
    pay_preference {method through_navore}
    addresses { id coordinates {lat, lng} full_address street_address is_main city state zip name weekdays time {to {hours format} from {hours format}} }
  }
`;


export const CORE_TOP_PRODUCERS = gql`
${CORE_PRODUCER_PROFILE_NO_INV}
${CORE_PRODUCER_PLAN_NO_PROFILE}
${CORE_PRODUCER_STORY}
${CORE_PRODUCER_INVENTORY}
fragment CoreTopProducers on TopProducer {
  _id
  producer_id
  profile {...CoreProducerProfileNoInv}
  plans {...CoreProducerPlanNoProfile}
  inventory {...CoreProducerInventory}
  stories {...CoreProducerStory}
  locations { type coordinates}
}
`;


export const CORE_TOP_PRODUCTS = gql`
${CORE_PRODUCER_PROFILE_NO_INV}
${CORE_PRODUCER_INVENTORY}
fragment CoreTopProducts on TopProduct {
  _id
  producer_id
  profile {...CoreProducerProfileNoInv}
  product {...CoreProducerInventory}
  pack_id

}
`;

/****************************************************
 *  START ALL QUERY's
 * ****************************************************/


export const GET_TOP_PRODUCERS = gql`
  ${CORE_TOP_PRODUCERS}
  query fetchTopProducers {
    topProducers {
      ...CoreTopProducers
    }
  }
`;


export const GET_TOP_PRODUCTS = gql`
  ${CORE_TOP_PRODUCTS}
  query fetchTopProducts {
    topProducts {
      ...CoreTopProducts
    }
  }
`;

export const FETCH_PRODUCE_BY_TYPE = gql`
  ${CORE_PRODUCT_CATEGORIES}
  query FetchAllProductCategories($type: String!) {
    productCategories (query: { type: $type }){
      ...CoreProductCategories
    }
  }
`;



export const GET_ALL_PRODUCT_CATEGORIES = gql`
  ${CORE_PRODUCT_CATEGORIES}
  query FetchAllProductCategories {
    productCategories (limit: 500){
      ...CoreProductCategories
    }
  }
  `;



/********************************************************************************/
//STORIES
export const ADD_TO_PRODUCER_STORIES = gql`
${CORE_PRODUCER_STORY}
mutation insertOneProducerStory($data: ProducerStoryInsertInput!) {
  insertOneProducerStory(data: $data) {
    ...CoreProducerStory
  }
}
`;

export const UPDATE_PRODUCER_STORY = gql`
${CORE_PRODUCER_STORY}

mutation ProducerStory($data: ProducerStoryUpdateInput!, $id: ObjectId!) {
  updateOneProducerStory( query: { _id: $id }, set: $data ) {
    ...CoreProducerStory
  }
}
`;

export const DELETE_ONE_PRODUCER_STORY = gql`
${CORE_PRODUCER_STORY}
mutation ProducerStory($id: ObjectId!) {
  deleteOneProducerStory ( query: { _id: $id } ){
    ...CoreProducerStory
  }
}
`;

export const GET_ALL_STORIES = gql`
${CORE_PRODUCER_STORY}
query FetchProducerStories($status: String!) {
  producerStories( query: { status: $status } ){
    ...CoreProducerStory
  }
}
`;

export const GET_ALL_PRODUCER_STORIES = gql`
${CORE_PRODUCER_STORY}
query fetchProducerStories($producer_id: String!) {
  producerStories( query: { producer_id: $producer_id} ){
    ...CoreProducerStory
  }
}
`;

export const GET_PRODUCER_STORIES = gql`
${CORE_PRODUCER_STORY}
query FetchProducerStory($producer_id: String!, $status:String!) {
  producerStories( query: { producer_id: $producer_id, status: $status } ){
    ...CoreProducerStory
  }
}
`;


export const GET_LATEST_PRODUCER_STORY = gql`
${CORE_PRODUCER_STORY}
query FetchProducerStory($producer_id: String!, $status:String!) {
  producerStory(query: { producer_id: $producer_id, status: $status } ){
    ...CoreProducerStory
  }
}
`;

//STORIES
/********************************************************************************/







/********************************************************************************/
//REVIEWS

export const GET_PRODUCER_REVIEWS = gql`
${CORE_REVIEW}
query fetchReviews($producer_id: String!) {
  reviews( query: { producer_id: $producer_id} ){
    ...CoreReview
  }
}
`;


//REVIEWS
/********************************************************************************/



//MEETING FORM
/********************************************************************************/

export const ADD_TO_MEETING_FORM_REQUEST = gql`
${CORE_MEETING_FORM_REQUEST}
mutation InsertMeetingFormRequest($data: MeetingFormRequestInsertInput!) {
insertOneMeetingFormRequest(data: $data) {
  ...CoreMeetingFormRequest
  }
}
`;

//MEETIN FORM
/********************************************************************************/



//DELIVERY LOADING
/********************************************************************************/



export const GET_ALL_DELIVERY_ROUTES = gql`
${CORE_DELIVERY_ROUTE}
query fetchDeliveryRoutes ($producer_id:String!, $archived:Boolean!) {
  deliveries ( query: { producer_id: $producer_id, archived: $archived } ){
    ...CoreDeliveryRoute
  }
}
`;

//DELIVERY LOADING
/********************************************************************************/




export const DELETE_ONE_UNCLAIMED_PRODUCER_ACCESS = gql`
${CORE_UNCLAIMED_PRODUCER_ACCESS}
mutation DeleteUnclaimedProducerAccess($uid: String!) {
  deleteOneUnclaimedProducerAccess( query: { uid: $uid } ){
    ...CoreUnclaimedProducerAccess
  }
}
`;


export const UPDATE_UNCLAIMED_PRODUCER_ACCESS = gql`
${CORE_UNCLAIMED_PRODUCER_ACCESS}
mutation UpdateUnclaimedProducerAccess($data: UnclaimedProducerAccessUpdateInput!, $uid:String!) {
  updateOneUnclaimedProducerAccess( query: { uid: $uid }, set: $data ) {
    ...CoreUnclaimedProducerAccess
  }
}
`;

export const GET_PRODUCER_UNCLAIMED_ACCESS = gql`
${CORE_UNCLAIMED_PRODUCER_ACCESS}
query FetchUnclaimedProducerAccesses($producer_id: String!) {
  unclaimedProducerAccesses( query: { producer_id: $producer_id } ){
    ...CoreUnclaimedProducerAccess
  }
}
`;



export const ADD_ONE_PRODUCER_UNCLAIMED_ACCESS = gql`
${CORE_UNCLAIMED_PRODUCER_ACCESS}
mutation InsertUnclaimedProducerAccess($data: UnclaimedProducerAccessInsertInput!) {
  insertOneUnclaimedProducerAccess( data: $data ) {
    ...CoreUnclaimedProducerAccess
  }
}
`;




/********************************************************************************/
//PLANS


export const GET_PRODUCER_PLANS = gql`
${CORE_PRODUCER_PLAN}
query FetchProducerPlans($producer_id: String!) {
  producerPlans( query: { producer_id: $producer_id } ){
    ...CoreProducerPlan
  }
}
`;

export const GET_PRODUCER_PLANS_NO_PROFILE = gql`
${CORE_PRODUCER_PLAN_NO_PROFILE}
query FetchProducerPlans($producer_id: String!) {
  producerPlans( query: { producer_id: $producer_id } ){
    ...CoreProducerPlanNoProfile
  }
}
`;

export const GET_ALL_PLANS = gql`
${CORE_PRODUCER_PLAN}
query FetchProducerPlans ($status:String!) {
  producerPlans( query: { status: $status } ) {
    ...CoreProducerPlan
  }
}
`;

export const GET_ALL_PLANS_NO_PROFILE = gql`
${CORE_PRODUCER_PLAN_NO_PROFILE}
query FetchProducerPlans ($status:String!) {
  producerPlans( query: { status: $status } ) {
    ...CoreProducerPlanNoProfile
  }
}
`;

export const ADD_TO_PRODUCER_PLANS = gql`
${CORE_PRODUCER_PLAN}
mutation InsertProducerPlan($data: ProducerPlanInsertInput!) {
insertOneProducerPlan(data: $data) {
  ...CoreProducerPlan
  }
}
`;

export const UPDATE_PRODUCER_PLAN = gql`
${CORE_PRODUCER_PLAN}
mutation UpdateProducerPlan($data: ProducerPlanUpdateInput!, $plan_id: ObjectId!) {
  updateOneProducerPlan( query: { _id: $plan_id }, set: $data ) {
    ...CoreProducerPlan
  }
}
`;


export const DELETE_ONE_PRODUCER_PLAN = gql`
${CORE_PRODUCER_PLAN}
mutation ProducerPlan($id: ObjectId!) {
  deleteOneProducerPlan( query: { _id: $id } ){
    ...CoreProducerPlan
  }
}
`;
//PLANS
/********************************************************************************/


/********************************************************************************/
//CERTIFICATIONS
export const GET_PRODUCER_CERTIFICATIONS = gql`
  ${CORE_PRODUCER_CERTIFICATION}
  query FetchProducerCertifications($producer_id: String!) {
    producerCertifications( query: { producer_id: $producer_id }, limit: 50 ){
      ...CoreProducerCertification
    }
  }
  `;

export const GET_CERTIFICATIONS_BY_APPROVAL = gql`
  ${CORE_PRODUCER_CERTIFICATION}
  query FetchProducerCertifications($approved: Boolean!) {
    producerCertifications( query: { approved: $approved }, limit: 500 ){
      ...CoreProducerCertification
    }
  }
  `;


export const GET_ALL_CERTIFICATIONS = gql`
  ${CORE_PRODUCER_CERTIFICATION}
  query FetchProducerCertifications {
    producerCertifications( limit: 500 ){
      ...CoreProducerCertification
    }
  }
  `;
//CERTIFICATIONS
/********************************************************************************/









/********************************************************************************/
//INVENTORY
export const GET_PRODUCER_INVENTORIES = gql`
  ${CORE_PRODUCER_INVENTORY}
  query ProducerInventory($producer_id: String!) {
    producerInventories( query: { producer_id: $producer_id }){
      ...CoreProducerInventory
    }
  }
  `;


export const SUBSCRIBE_PRODUCER_INVENTORIES = gql`
  ${CORE_PRODUCER_INVENTORY}
  subscription ProducerInventory($producer_id: String!) {
    producerInventories(producer_id: $producer_id) {
      ...CoreProducerInventory
    }
  }
`


export const ADD_TO_PRODUCER_INVENTORY = gql`
  ${CORE_PRODUCER_INVENTORY}
  mutation insertOneProducerInventory($data: ProducerInventoryInsertInput!) {
  insertOneProducerInventory(data: $data) {
    ...CoreProducerInventory
    }
  }
  `;

export const INSERT_MANY_PRODUCER_INVENTORY = gql`
${CORE_PRODUCER_INVENTORY}
mutation insertManyProducerInventories($data: [ProducerInventoryInsertInput!]!) {
  insertManyProducerInventories(data: $data) {
    ...CoreProducerInventory
  }
}
`;

export const UPDATE_PRODUCER_INVENTORY = gql`
  ${CORE_PRODUCER_INVENTORY}
  mutation ProducerInventory($data: ProducerInventoryUpdateInput!, $product_id: ObjectId!) {
    updateOneProducerInventory( query: { _id: $product_id }, set: $data ) {
      ...CoreProducerInventory
    }
  }
  `;

export const DELETE_ONE_PRODUCER_INVENTORY = gql`
  ${CORE_PRODUCER_INVENTORY}
  mutation ProducerInventory($id: ObjectId!) {
    deleteOneProducerInventory ( query: { _id: $id } ){
      ...CoreProducerInventory
    }
  }
  `;

export const GET_ONE_PRODUCT = gql`
    ${CORE_PRODUCER_INVENTORY}
    query ProducerInventory($id: ObjectId!) {
      producerInventory( query: { _id: $id } ){
        ...CoreProducerInventory
      }
    }
  `;
//INVENTORY
/********************************************************************************/








/********************************************************************************/
//RECIPES
export const ADD_TO_PRODUCER_RECIPES = gql`
  ${CORE_RECIPE}
  mutation insertOneRecipe($data: RecipeInsertInput!) {
    insertOneRecipe(data: $data) {
      ...CoreRecipe
    }
  }
  `;

export const UPDATE_PRODUCER_RECIPE = gql`
  ${CORE_RECIPE}

  mutation Recipes($data: RecipeUpdateInput!, $_id: ObjectId!) {
    updateOneRecipe( query: { _id: $_id }, set: $data ) {
      ...CoreRecipe
    }
  }
  `;

export const DELETE_ONE_PRODUCER_RECIPE = gql`
  ${CORE_RECIPE}
  mutation Recipes($id: ObjectId!) {
    deleteOneRecipe ( query: { _id: $id } ){
      ...CoreRecipe
    }
  }
  `;

export const GET_PRODUCER_RECIPES = gql`
  ${CORE_RECIPE}
  query FetchRecipes($producer_id: String!,$status: String!) {
    recipes( query: { producer_id: $producer_id, status:$status } ){
      ...CoreRecipe
    }
  }
  `;

export const GET_ALL_PRODUCER_RECIPES = gql`
${CORE_RECIPE}
query FetchRecipes($producer_id: String!) {
  recipes( query: { producer_id: $producer_id} ){
    ...CoreRecipe
  }
}
`;


export const GET_ALL_RECIPES = gql`
${CORE_RECIPE}
query FetchRecipes($status: String!) {
  recipes( query: {status:$status } ){
    ...CoreRecipe
  }
}
`;

export const GET_PRODUCT_RECIPES = gql`
${CORE_RECIPE}
query FetchRecipes($producer_id: String!,$status: String!) {
  recipes( query: { producer_id: $producer_id} ){
    ...CoreRecipe
  }
}
`;

export const GET_RECIPE = gql`
  ${CORE_RECIPE}
  query FetchRecipe($id: ObjectId!) {
    recipe( query: { _id: $id } ){
      ...CoreRecipe
    }
  }
  `;

export const GET_RECIPE_WITH_PROFILE = gql`
${CORE_RECIPE_WITH_PROFILE}
query fetchRecipe($id: ObjectId!) {
  recipe( query: { _id: $id } ){
    ...CoreRecipeWithProfile
  }
}
`;
//RECIPES
/********************************************************************************/





/*************************************************************************/
//CART

export const GET_MY_CART = gql`
    ${CORE_CART}
    query fetchCarts($uid: String!) {
      carts( query: { uid: $uid } ){
        ...CoreCart
      }
    }
  `;

export const UPDATE_ONE_CART = gql`
${CORE_CART}
mutation updateCart($data: CartUpdateInput!, $cartId: ObjectId!) {
  updateOneCart(query: { _id: $cartId }, set: $data ) {
    ...CoreCart
  }
}
`;

export const ADD_TO_CART = gql`
${CORE_CART}
mutation insertOneCart($data: CartInsertInput!) {
  insertOneCart(data: $data ) {
    ...CoreCart
  }
}
`;


//CART
/************************************************************************ */




/************************************************************************ */
//Wholesale

export const GET_ALL_WHOLESALE_CUSTOMERS = gql`
  ${CORE_PRODUCER_WHOLESALE_CUSTOMER}
      query FetchProducerWholesaleCustomers($producer_id: String!) {
        producerWholesaleCustomers( query: { producer_id: $producer_id } ){
         ...CoreProducerWholesaleCustomer
        }
      }
  `;

export const UPDATE_ONE_WHOLESALE_CUSTOMER = gql`
    ${CORE_PRODUCER_WHOLESALE_CUSTOMER}
    mutation updateOneProducerWholesaleCustomer($data:ProducerWholesaleCustomerUpdateInput!, $id: ObjectId!) {
      updateOneProducerWholesaleCustomer(query: { _id: $id }, set: $data ) {
        ...CoreProducerWholesaleCustomer
      }
    }
  `;
export const DELETE_ONE_WHOLESALE_CUSTOMER = gql`
  ${CORE_PRODUCER_WHOLESALE_CUSTOMER}
  mutation deleteOneProducerWholesaleCustomer($id: ObjectId!) {
    deleteOneProducerWholesaleCustomer ( query: { _id: $id } ){
      ...CoreProducerWholesaleCustomer
    }
  }
  `;

export const ADD_TO_WHOLESALE_CUSTOMERS = gql`
  ${CORE_PRODUCER_WHOLESALE_CUSTOMER}
  mutation insertOneProducerWholesaleCustomer($data: ProducerWholesaleCustomerInsertInput!) {
    insertOneProducerWholesaleCustomer(data: $data) {
      ...CoreProducerWholesaleCustomer
    }
  }
  `;



export const GET_ALL_WHOLESALE_GROUPS = gql`
  ${CORE_PRODUCER_WHOLESALE_GROUP}
      query fetchProducerWholesaleGroups($producer_id: String!) {
        producerWholesaleGroups( query: { producer_id: $producer_id } ){
         ...CoreProducerWholesaleGroup
        }
      }
  `;

export const UPDATE_ONE_WHOLESALE_GROUP = gql`
    ${CORE_PRODUCER_WHOLESALE_GROUP}
    mutation updateOneProducerWholesaleGroup($data:ProducerWholesaleGroupUpdateInput!, $id: ObjectId!) {
      updateOneProducerWholesaleGroup(query: { _id: $id }, set: $data ) {
        ...CoreProducerWholesaleGroup
      }
    }
  `;
export const DELETE_ONE_WHOLESALE_GROUP = gql`
  ${CORE_PRODUCER_WHOLESALE_GROUP}
  mutation deleteOneProducerWholesaleGroup($id: ObjectId!) {
    deleteOneMProducerWholesaleGroup ( query: { _id: $id } ){
      ...CoreProducerWholesaleGroup
    }
  }
  `;

export const ADD_TO_WHOLESALE_GROUPS = gql`
  ${CORE_PRODUCER_WHOLESALE_GROUP}
  mutation insertOneProducerWholesaleGroup($data: ProducerWholesaleGroupInsertInput!) {
    insertOneProducerWholesaleGroup(data: $data) {
      ...CoreProducerWholesaleGroup
    }
  }
  `;

//Wholesale
/********************************************************************************/




/********************************************************************************/
//PROFILES
export const SAVE_PRIVATE_PROFILE = gql`
  ${CORE_PRIVATE_PROFILE}
  mutation SavePrivateProfile($profile: PrivateProfileUpdateInput!, $uid:String!) {
    updateOnePrivateProfile(query: { uid: $uid }, set:  $profile ) {
     ...CorePrivateProfile
    }
  }
`;

export const GET_PRIVATE_PROFILE = gql`
  ${CORE_PRIVATE_PROFILE}
      query FetchPrivateProfile($uid: String!) {
        privateProfile( query: { uid: $uid } ){
         ...CorePrivateProfile
        }
      }
  `;

export const GET_PUBLIC_PROFILE = gql`
    ${CORE_PUBLIC_PROFILE}
    
    query FetchPublicProfile($uid: String!) {
      publicProfile( query: { uid: $uid } ){
        ...CorePublicProfile
      }
    }
  `;

export const GET_PUBLIC_PROFILE_WITH_EMAIL = gql`
  ${CORE_PUBLIC_PROFILE}

  query FetchPublicProfile($email: String!) {
    publicProfile( query: { email: $email } ){
      ...CorePublicProfile
    }
  }
`;

export const UPDATE_PRODUCER_PROFILE = gql`
  ${CORE_PRODUCER_PROFILE}
  mutation UpdateProducerProfile($data: ProducerProfileUpdateInput!, $producer_id: String!) {
    updateOneProducerProfile(query: { producer_id: $producer_id }, set: $data ) {
      ...CoreProducerProfile
    }
  }
  `;

export const GET_PRODUCER_PUBLIC_PROFILE = gql`
      ${CORE_PRODUCER_PROFILE}
      query FetchProducerProfile($producer_id: String!) {
        producerProfile( query: { producer_id: $producer_id } ){
          ...CoreProducerProfile
        }
      }
`;

export const GET_ALL_PRODUCER_PROFILES = gql`
      ${CORE_PRODUCER_PROFILE}
      query FetchProducerProfiles {
        producerProfiles {
          ...CoreProducerProfile
        }
      }
`;


export const GET_PRODUCER_PROFILE_BY_URL = gql`
${CORE_PRODUCER_PROFILE}
query FetchProducerProfile($url: String!) {
  producerProfile( query: { url: $url } ){
    ...CoreProducerProfile
  }
}
`;


export const UPDATE_ONE_PUBLIC_PROFILE = gql`
  ${CORE_PUBLIC_PROFILE}
  mutation SavePublicProfile($profile: PublicProfileUpdateInput!, $uid:String!) {
    updateOnePublicProfile(query: { uid: $uid }, set:  $profile ) {
      ...CorePublicProfile
    }
  }
  `;


export const ADD_TO_TEMPORARY_PRODUCERS = gql`
  ${CORE_TEMPORARY_PRODUCER}
  mutation insertOneTemporaryProducer($data: TemporaryProducerInsertInput!) {
  insertOneTemproraryProducer(data: $data) {
    ...CoreTemporaryProducer
    }
  }
  `;
//PROFILES
/********************************************************************************/



//MEDIA GALLERY
export const GET_MEDIA_GALLERY = gql`
      ${CORE_MEDIA_GALLERY}
      query fetchMediaGalleries($producer_id: String!) {
        mediaGalleries( query: { producer_id: $producer_id} ){
          ...CoreMediaGallery
        }
      }
  `;

export const GET_PUBLISHED_MEDIA = gql`
      ${CORE_MEDIA_GALLERY}
      query fetchAllMediaGalleries($producer_id: String!, $status:String!) {
        mediaGalleries( query: { producer_id: $producer_id, status: $status} ){
          ...CoreMediaGallery
        }
      }
  `;


export const GET_ALL_MEDIA = gql`
      ${CORE_MEDIA_GALLERY}
      query fetchAllMediaGalleries($status:String!) {
        mediaGalleries( query: {status: $status} ){
          ...CoreMediaGallery
        }
      }
  `;

export const UPDATE_MEDIA_GALLERY = gql`
    ${CORE_MEDIA_GALLERY}
    mutation updateOneMediaGallery($data: MediaGalleryUpdateInput!, $id: ObjectId!) {
      updateOneMediaGallery(query: { _id: $id }, set: $data ) {
        ...CoreMediaGallery
      }
    }
  `;
export const DELETE_ONE_MEDIA_GALLERY = gql`
  ${CORE_MEDIA_GALLERY}
  mutation MediaGallery($id: ObjectId!) {
    deleteOneMediaGallery ( query: { _id: $id } ){
      ...CoreMediaGallery
    }
  }
  `;

export const ADD_TO_MEDIA_GALLERY = gql`
  ${CORE_MEDIA_GALLERY}
  mutation insertOneMediaGallery($data: MediaGalleryInsertInput!) {
    insertOneMediaGallery(data: $data) {
      ...CoreMediaGallery
    }
  }
  `;
//MEDIA GALLERY
/********************************************************************************/




/********************************************************************************/
//PRODUCER ACCESS
export const GET_PRODUCER_ACCESS = gql`
  ${CORE_PRODUCER_ACCESS}
  query fetchProducerAccess($producer_id: String!) {
    producerAccess( query: { producer_id: $producer_id } ){
      ...CoreProducerAccess
    }
  }
`;

export const UPDATE_PRODUCER_ACCESS = gql`
${CORE_PRODUCER_ACCESS}
mutation ProducerAccess($id: String!, $data: ProducerAccessUpdateInput!) {
  updateOneProducerAccess( query: { producer_id: $id }, set: $data ) {
    ...CoreProducerAccess
  }
}
`;

//PRODUCER ACCESS
/********************************************************************************/



/********************************************************************************/
//PAYMENT SETTINGS
export const GET_CUSTOMER_PAYMENTS = gql`
  ${CORE_PAYMENT_TRANSACTION}
  query fetchPaymentTransactions($customer_id: String!) {
    paymentTransactions( query: { customer_id: $customer_id } ){
      ...CorePaymentTransaction
    }
  }
`;


export const GET_PRODUCER_PAYMENT_HISTORY = gql`
  ${CORE_PAYMENT_TRANSACTION}
  query fetchPaymentTransactions($producer_id: String!) {
    paymentTransactions( query: { producer_id: $producer_id } ){
      ...CorePaymentTransaction
    }
  }
`;

export const UPDATE_CUSTOMER_PAYMENTS = gql`
${CORE_PAYMENT_TRANSACTION}
mutation PaymentTransaction($id: String!, $data: PaymentTransactionUpdateInput!) {
  updateOnePaymentTransaction( query: { customer_id: $id }, set: $data ) {
    ...CorePaymentTransaction
  }
}
`;


export const GET_PRODUCER_PAYMENT_SETTINGS = gql`
  ${CORE_PRODUCER_PAYMENT_SETTING}
  query fetchPaymentSetting($producer_id: String!) {
    paymentSetting( query: { producer_id: $producer_id } ){
      ...CorePaymentSetting
    }
  }
`;

export const UPDATE_PRODUCER_PAYMENT_SETTINGS = gql`
${CORE_PRODUCER_PAYMENT_SETTING}
mutation PaymentSettngs($producer_id: String!, $data: PaymentSettingUpdateInput!) {
  updateOnePaymentSetting( query: { producer_id: $producer_id }, set: $data ) {
    ...CorePaymentSetting
  }
}
`;

//PRODUCER SETTINGS
/********************************************************************************/


/********************************************************************************/
//START -- Orders
export const GET_ALL_ORDERS = gql`
${CORE_ORDER}
query fetchAllOrders($producer_id: String!) {
  orders( query: { producer_id: $producer_id } ){
    ...CoreOrder

  }
}
`;


export const GET_ALL_RECURRING_ORDERS = gql`
${CORE_ORDER}
query fetchAllRecurringOrders($producer_id: String!) {
  recurringOrders( query: { producer_id: $producer_id } ){
    ...CoreRecurringOrder
  }
}
`;

export const GET_ALL_PRODCER_ORDERS_FOR_CUSTOMER = gql`
${CORE_ORDER}
query fetchAllOrders($producer_id: String!, $customer_id: String!) {
  orders( query: { producer_id: $producer_id, customer_id: $customer_id } ){
    ...CoreOrder

  }
}
`;


export const GET_ALL_ORDER_ITEMS = gql`
${CORE_ORDER_ITEM}
query fetchAllOrderItems($producer_id: String!) {
  orderItems( query: { producer_id: $producer_id } ){
    ...CoreOrderItem
  }
}`;



export const GET_ALL_CUSTOMER_ORDERS = gql`
${CORE_ORDER}
query fetchOrders($customer_id: String!) {
  orders( query: { customer_id: $customer_id } ){
    ...CoreOrder
  }
}
`;

export const GET_ALL_OPEN_ORDERS = gql`
  ${CORE_ORDER}
    query FetchAllOpenOrders($order_status: Array!, $producer_id:String!) {
      orders ( query: {  producer_id: $producer_id } ){
        ...CoreOrder
        }
      }
`;



export const UPDATE_REVIEW_REPOSTED = gql`
${CORE_REVIEW}
mutation Review($data: ReviewUpdateInput!, $_id: ObjectId!) {
  updateOneReview( query: { _id: $_id }, set: $data ) {
    ...CoreReview
  }
}
`;

export const UPDATE_ORDER = gql`
${CORE_ORDER}
mutation Order($data: OrderUpdateInput!, $_id: ObjectId!) {
  updateOneOrder( query: { _id: $_id }, set: $data ) {
    ...CoreOrder
  }
}
`;

export const UPDATE_ORDER_ITEM = gql`
${CORE_ORDER_ITEM}
mutation OrderItem($data: OrderItemUpdateInput!, $_id: ObjectId!) {
  updateOneOrderItem( query: { _id: $_id }, set: $data ) {
    ...CoreOrderItem
  }
}
`;

export const INSERT_ORDER = gql`
${CORE_ORDER}
mutation insertOneOrder($data: OrderInsertInput!) {
  insertOneOrder( data: $data ) {
    ...CoreOrder
  }
}
`;

export const INSERT_RECURRING_ORDER = gql`
${CORE_RECURRING_ORDER}
mutation insertOneRecurringOrder($data: RecurringOrderInsertInput!) {
  insertOneRecurringOrder( data: $data ) {
    ...CoreRecurringOrder
  }
}
`;


export const INSERT_MANY_ORDER_ITEMS = gql`
${CORE_ORDER_ITEM}
  mutation insertManyOrderItems($data: [OrderItemInsertInput!]!) {
    insertManyOrderItems(data: $data) {
      ...CoreOrderItem
    }
  }
`;
export const INSERT_ORDER_ITEM = gql`
${CORE_ORDER_ITEM}
  mutation insertOrderItem($data: OrderItemInsertInput!) {
    insertOneOrderItem(data: $data) {
      ...CoreOrderItem
    }
  }
`;

//END -- Orders
/********************************************************************************/


//START -- METRICS
/********************************************************************************/
export const INSERT_METRIC = gql`
${CORE_METRIC}
mutation insertOneMetric($data: MetricInsertInput!) {
  insertOneMetric( data: $data ) {
    ...CoreMetric
  }
}
`;


//END -- METRICS
/********************************************************************************/




/********************************************************************************/
//START -- SCHEDULED NOTIFICATIONS

export const GET_SCHEDULED_NOTIFICATIONS_FOR_PRODUCT = gql`
  ${CORE_SCHEDULED_NOTIFICATION}
    query fetchScheduledNotifications($primary_id: String!) {
      scheduledNotification ( query: {  primary_id: $primary_id} ){
        ...CoreScheduledNotification
        }
      }
  `;

export const GET_SCHEDULED_NOTIFICATIONS_FOR_PRODUCT_AND_USER = gql`
  ${CORE_SCHEDULED_NOTIFICATION}
    query fetchScheduledNotifications($primary_id: String!, $recipient_id:String!) {
      scheduledNotification ( query: {  primary_id: $primary_id, recipient_id:$recipient_id} ){
        ...CoreScheduledNotification
        }
      }
  `;

export const GET_SCHEDULED_NOTIFICATIONS = gql`
  ${CORE_SCHEDULED_NOTIFICATION}
    query fetchScheduledNotifications( $recipient_id:String!) {
      scheduledNotification ( query: { recipient_id:$recipient_id } ){
        ...CoreScheduledNotification
        }
      }
  `;
export const GET_NOTIFICATIONS = gql`
${CORE_NOTIFICATION}
  query fetchNotifications( $recipient_id:String!) {
    notifications ( query: { recipient_id:$recipient_id } ){
      ...CoreNotification
      }
    }
`;

export const GET_MESSAGES_SENDER = gql`
  ${CORE_MESSAGE}
    query fetchInbox( $sender_id:String!) {
      inbox ( query: { sender_id:$sender_id } ){
        ...CoreMessage
        }
      }
  `;


export const GET_MESSAGES_RECEIVER = gql`
${CORE_MESSAGE}
  query fetchInbox( $recipient_id:String!) {
    inbox ( query: { recipient_id:$recipient_id } ){
      ...CoreMessage
      }
    }
`;


export const GET_REQUESTED_REFUNDS = gql`
  ${CORE_REFUND}
  query fetchRefunds( $producer_id:String!, $status:String!) {
    refunds ( query: { producer_id:$producer_id, status:$status } ){
      ...CoreRefund
      }
    }
`;

export const UPDATE_REQUESTED_REFUND = gql`
${CORE_REFUND}
  mutation updateRefund( $data:RefundUpdateInput!, $refund_id: ObjectId!) {
    updateOneRefund ( query: { _id: $refund_id }, set:$data ){
      ...CoreRefund
      }
    }
`;


// END -- SCHEDULED NOTIFICATIONS
/********************************************************************************/




/********************************************************************************/
//START -- TOOL LISTS
export const GET_PRODUCER_LIST_MANAGER = gql`
${CORE_LIST_MANAGER}
  query fetchListManager($producer_id: String!) {
    listsManager ( query: {  producer_id: $producer_id } ){
      ...CoreListsManager
      }
    }
`;

export const UPDATE_PRODUCER_LIST_MANAGER = gql`
${CORE_LIST_MANAGER}
  mutation listsManager($list_id: ObjectId!, $data:ListsManagerUpdateInput!) {
    updateOneListsManager ( query: { _id: $list_id }, set:$data ){
      ...CoreListsManager
      }
    }
`;


export const INSERT_PRODUCER_LIST_MANAGER = gql`
${CORE_LIST_MANAGER}
  mutation listsManager($data:ListsManagerInsertInput!) {
    insertOneListsManager (  data:$data ){
      ...CoreListsManager
      }
    }
`;

export const GET_PRODUCER_LIST_GROUPS = gql`
${CORE_LIST_GROUPS}
  query fetchAllListGroups($producer_id: String!) {
    listGroups ( query: {  producer_id: $producer_id } ){
      ...CoreListGroup
      }
    }
`;

export const UPDATE_PRODUCER_LIST_GROUP = gql`
${CORE_LIST_GROUPS}
  mutation listGroup($data:ListGroupUpdateInput!, $_id: ObjectId!) {
    updateOneListGroup ( query: { _id: $_id }, set:$data ){
      ...CoreListGroup
      }
    }
`;


export const INSERT_PRODUCER_LIST_GROUP = gql`
  ${CORE_LIST_GROUPS}
  mutation insertOneListGroup($data: ListGroupInsertInput!) {
    insertOneListGroup( data: $data ) {
      ...CoreListGroup
    }
  }
`;

export const DELETE_PRODUCER_LIST_GROUP = gql`
${CORE_LIST_GROUPS}
mutation ListGroup($id: ObjectId!) {
  deleteOneListGroup( query: { _id: $id } ){
    ...CoreListGroup
  }
}
`;


export const GET_PRODUCER_DAILY_HARVEST_LISTS = gql`
${CORE_DAILY_HARVEST_LIST}
  query fetchAllDailyHarvestLists($producer_id: String!, $archived:Boolean!) {
    dailyHarvestLists ( query: { producer_id: $producer_id, archived: $archived} ){
      ...CoreDailyHarvestList
      }
    }
`;


export const GET_PRODUCER_DAILY_PACKING_LISTS = gql`
${CORE_DAILY_PACKING_LIST}
  query fetchDailyPackingLists($producer_id: String!, $archived:Boolean!) {
    dailyPackingLists ( query: {  producer_id: $producer_id, archived: $archived } ){
      ...CoreDailyPackingList
      }
    }
`;

export const GET_PRODUCER_DAILY_PREPACKING_LISTS = gql`
${CORE_DAILY_PREPACKING_LIST}
  query fetchDailyPrepackingLists($producer_id: String!, $archived:Boolean!) {
    dailyPrepackingLists ( query: {  producer_id: $producer_id, archived: $archived } ){
      ...CoreDailyPrepackingList
      }
    }
`;


export const UPDATE_PRODUCER_DAILY_HARVEST_LIST = gql`
${CORE_DAILY_HARVEST_LIST}
mutation dailyHarvestList($data:DailyHarvestListUpdateInput!, $_id: ObjectId!) {
  updateOneDailyHarvestList ( query: { _id: $_id }, set:$data ){
      ...CoreDailyHarvestList
      }
    }
`;


export const UPDATE_PRODUCER_DAILY_PACKING_LIST = gql`
${CORE_DAILY_PACKING_LIST}
  mutation dailyPackingList($data:DailyPackingListUpdateInput!, $_id: ObjectId!) {
    updateOneDailyPackingList ( query: {  _id: $_id }, set:$data ){
      ...CoreDailyPackingList
      }
    }
`;

export const UPDATE_PRODUCER_DAILY_PREPACKING_LIST = gql`
${CORE_DAILY_PREPACKING_LIST}
mutation dailyPrepackingList($data:DailyPrepackingListUpdateInput!, $_id: ObjectId!) {
  updateOneDailyPrepackingList ( query: { _id: $_id }, set:$data ){
      ...CoreDailyPrepackingList
      }
    }
`;



export const UPDATE_PRODUCER_DELIVERY_ROUTE = gql`
${CORE_DELIVERY_ROUTE}
mutation delivery($data:DeliveryUpdateInput!, $_id: ObjectId!) {
  updateOneDelivery ( query: { _id: $_id }, set:$data ){
      ...CoreDeliveryRoute
      }
    }
`;


export const GET_ONE_SITE_INFORMATION = gql`
${CORE_SITE_INFORMATION}
query FindOneSiteInformation($unique_name: String!) {
  siteInformation ( query: { unique_name: $unique_name }){
      ...CoreSiteInformation
      }
    }
`;


// END -- TOOL LISTS
/********************************************************************************/

const CORE_TEMPORARY_ADMIN = gql`
  fragment CoreTemporaryAdmin on TemporaryAdmin {
      _id
      uid 
      producer_id 
      producer_profile { name _id }
      user_profile { full_name email uid}
      created
      expired
  }
`;


//Temporary Access
export const GET_TEMPORARY_ADMINS = gql`
${CORE_TEMPORARY_ADMIN}
query fetchTemporaryAdmins($_partition: String!, $producer_id:String! $expired:Boolean) {
  temporaryAdmin( query: { uid: $_partition, producer_id:$producer_id, expired: $expired} ){
    ...CoreTemporaryAdmin
  }
}
`;


//Temporary Access
export const GET_ALL_TEMPORARY_ADMINS = gql`
${CORE_TEMPORARY_ADMIN}
query fetchTemporaryAdmins($expired:Boolean) {
temporaryAdmins (query:{expired: $expired}){
...CoreTemporaryAdmin
}
}`;

export const ADD_TO_TEMPORARY_ADMINS = gql`
${CORE_TEMPORARY_ADMIN}
mutation insertOneTemporaryAdmin($data: TemporaryAdminInsertInput!) {
insertOneTemporaryAdmin(data: $data) {
...CoreTemporaryAdmin
}
}`;

export const GET_ONE_PRODUCER_PROFILE = gql`
  ${CORE_PRODUCER_PROFILE}
  query FetchProducerProfile($_id: ObjectId!) {
    producerProfile( query: { _id: $_id } ){
     ...CoreProducerProfile
    }
  }
`;

export const UPDATE_ONE_CERTIFICATION = gql`
  ${CORE_PRODUCER_CERTIFICATION}

  mutation SaveProducerCertification($data: ProducerCertificationUpdateInput!, $id:ObjectId) {
    updateOneProducerCertification(query: { _id: $id }, set:  $data ) {
      ...CoreProducerCertification
    }
  }
`;


/*****************
*  END ALL QUERY's
* ******************/


