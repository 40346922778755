export const toggleBoolean = (prev) => !prev;
export const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
export const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'november', 'december']
export const produceSearchTypes = ['fruit', 'vegetables', 'dairy','mushrooms','flowers', 'pantry', 'bakery', 'meat', 'herbs']

const isValidArrayIndex = (arr, idx) => {
  return !(idx < 0 || idx >= arr.length);
};

export function addValueAtIndex(arr, idx, value) {
  if (!isValidArrayIndex(arr, idx) && idx !== arr.length) {
    throw new Error(`Cannot add value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), value, ...arr.slice(idx)];
}

export function replaceValueAtIndex(arr, idx, newValue) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot replace value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), newValue, ...arr.slice(idx + 1)];
}

export function updateValueAtIndex(arr, idx, updater) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot update value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), updater(arr[idx]), ...arr.slice(idx + 1)];
}

export function removeValueAtIndex(arr, idx) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot remove value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
}

export const isSameTodo = (todo1, todo2) =>
  String(todo1?._id) === String(todo2?._id);

export const getTodoIndex = (todos, todo) => {
  const idx = todos.findIndex((t) => isSameTodo(t, todo));
  return idx >= 0 ? idx : null;
};


//Returns next available day for pickup, with a time range
// ex: {day:'monday', availability:timerange}
export const calculateNextAvailablePickup = (producerProfile) => {

   //get today's date
   let minimumThreshholdDate = new Date();

  //calculate minimum threshold for action
  //Minimum threshold is collectionTime(in hours) set by producer added to current time
  let collectionTime = producerProfile.minimum_collection_time_hours
  minimumThreshholdDate.setTime(minimumThreshholdDate.getTime() + (collectionTime*60*60*1000));

   //minimum day threshold for delivery
  let minThresholdDayIndex  = minimumThreshholdDate.getDay();
  let firstPossiblePickupDayOfWeek= days[minThresholdDayIndex]

  //first check if the producer has pickup on the minDayOfWeek and the time falls between the aviable ranges 
  if(producerProfile.pickup_days.includes(firstPossiblePickupDayOfWeek)){

    let minThresholdDayRanges = producerProfile.pickup_schedule[firstPossiblePickupDayOfWeek]

    for(const range of minThresholdDayRanges){
      if(isInDayRange(range, minimumThreshholdDate)){
        //the next available day is this one
        return {date:minimumThreshholdDate, day:firstPossiblePickupDayOfWeek, availability:[range]}
      }
    }
  }


  //if we make it here the first possible pickup day doesn't fall into the range of accepted available pickup times.
  //Find the next available pickup day.
  //Iterate days starting with the day after the minimum pickup day.
  let total = 0
  let startIndex = (minThresholdDayIndex + 1) % days.length

  while(total < days.length){

    let day = days[startIndex]
    minimumThreshholdDate.setDate(minimumThreshholdDate.getDate() + 1);

    if(producerProfile.pickup_days.includes(day)){
      let minThresholdDayRanges = producerProfile.pickup_schedule[firstPossiblePickupDayOfWeek]

      return {date:minimumThreshholdDate, day:day, availability:minThresholdDayRanges}
    }
    startIndex += (1 % days.length)
    total += 1

  }
}


export const calculateNextAvailableDelivery = (producerProfile) => {
      //get today's date
      let minimumThreshholdDate = new Date();

      //calculate minimum threshold for action
      //Minimum threshold is collectionTime(in hours) set by producer added to current time
      let collectionTime = producerProfile.minimum_collection_time_hours
      minimumThreshholdDate.setTime(minimumThreshholdDate.getTime() + (collectionTime*60*60*1000));

      //minimum day threshold for delivery
      let minThresholdDayIndex = minimumThreshholdDate.getDay();
      let firstPossibleDeliveryDayOfWeek = days[minThresholdDayIndex]

      //first check if the producer has pickup on the minDayOfWeek and the time falls between the aviable ranges 
      if(producerProfile.delivery_days.includes(firstPossibleDeliveryDayOfWeek)){

         let minThresholdDayRanges = producerProfile.delivery_schedule[firstPossibleDeliveryDayOfWeek]

         for(const range of minThresholdDayRanges){
            if(isInDayRange(range, minimumThreshholdDate)){
               //the next available day is this one

               return {date:minimumThreshholdDate, day:firstPossibleDeliveryDayOfWeek, availability:[range]}
            }
         }
      }

      //if we make it here the first possible pickup day doesn't fall into the range of accepted available pickup times.
      //Find the next available pickup day.
      //Iterate days starting with the day after the minimum pickup day.
      let total = 0
      let startIndex = (minThresholdDayIndex + 1) % days.length


      while(total < days.length){

         let day = days[startIndex]
         minimumThreshholdDate.setDate(minimumThreshholdDate.getDate() + 1);


         if(producerProfile.delivery_days.includes(day)){

            let minThresholdDayRanges = producerProfile.delivery_schedule[firstPossibleDeliveryDayOfWeek]
            return {date: minimumThreshholdDate, day: day, availability: minThresholdDayRanges}
         }

         startIndex += (1 % days.length)
         total += 1
      }
}


export const formatUSDCostToTwoDecimals = (cost) =>{
  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  let formatted = formatter.format(cost)
  return formatted
}


const isInDayRange = (range, targetDay) => {

      let targetMillis = targetDay.getTime()

      //split the rangeArr into min/max
      let rangeArr = range.split('--')
      let min = rangeArr[0]
      let max = rangeArr[1]

      //parse minDate object from min in range, set day to target day for comparison
      let minDate = new Date(min)

      //create new date from targetDay and set hours, mins from the ranges minDate
      let finalMinDate = targetDay
      finalMinDate.setHours(minDate.getHours())
      finalMinDate.setMinutes(minDate.getMinutes())

      //convert to millis for comparison
      let minMillis = finalMinDate.getTime()

      //parse maxDate object from range
      let maxDate = new Date(max)

      //create new date from targetDay and set hours, mins from the ranges minDate
      let finalMaxDate = targetDay
      finalMaxDate.setHours(maxDate.getHours())
      finalMaxDate.setMinutes(maxDate.getMinutes())

      //convert to millis for comparison
      let maxMillis = finalMaxDate.getTime()

      if(targetMillis > minMillis && targetMillis < maxMillis){
         return true
      }

      return false
}
