import React from "react";
import { Typography, Stack } from "@mui/material";

export const API_TYPE_NAME = "GQL"

export function AppName() {
  return (

    <Stack className="app-bar-title" direction='row' spacing={1}>
        <Typography  component="h1" variant="h5">
          NÁVORE 
        </Typography>

        <Typography  component="h1" variant="subtitle2">
          admin 
        </Typography>
    </Stack>

  );
}
