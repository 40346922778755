import React from "react";
import * as Realm from "realm-web";


import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useRealmApp } from "../RealmApp";
import { MoreInfoItem } from "../common/MoreInfo";
import { toggleBoolean } from "../../utils";
import { useErrorAlert } from "../../hooks/useErrorAlert";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export function ResetPage() {

  const realmApp = useRealmApp();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // Track whether the user is logging in or signing up for a new account

  // Authentication errors
  const noErrors = {
    email: null,
    password: null,
    other: null,
  };


  const [error, setError] = React.useState(noErrors);
  const clearErrors = () => setError(noErrors);
  const NonAuthErrorAlert = useErrorAlert({
    error: error.other,
    clearError: () => {
      setError((prevError) => ({ ...prevError, other: null }));
    },
  });
  // Manage password visibility
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);

  const [passwordResetComplete, setPasswordResetComplete] = React.useState(false)
  const toggleShowPassword = () => setShowPassword(toggleBoolean);
  const toggleShowPassword2 = () => setShowPassword2(toggleBoolean);



  const onFormSubmit = async ({ password1, password2 }) => {
    clearErrors();

    if(password1 !== password2){
      setError((prevError) => ({
        ...prevError,
        other: t('passwordDoNotMatch'),
      }));
      return
    }
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const tokenId = params.get("tokenId");

    if(tokenId !== "" && token !== ""){
        //we don't need to verify security questions and can just reset the email
        let res = await realmApp.emailPasswordAuth.resetPassword({password:password1, token, tokenId})
        console.log(res)
        setPasswordResetComplete(true)
      }
  };


  const toLogin = () =>{
    navigate("/login", {replace:true})
  }


  return (

    <Container className="main-container">
      <Container fixed>
      <Card className="auth-card">
        <form
          className="auth-form"
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            const { password1, password2 } = Object.fromEntries(formData.entries());
            onFormSubmit({ password1, password2 });
          }}
        >
          {!passwordResetComplete ? <Typography component="h2" variant="h4" gutterBottom>
            {t('passwordReset')}
          </Typography> : <Typography component="h2" gutterBottom>
            {t('passwordHasBeenReset')}
          </Typography>}

          <NonAuthErrorAlert />

          {!passwordResetComplete && <TextField
              id="input-password"
              type={showPassword ? "text" : "password"}
              name="password1"
              label={t('newPassword')}
              variant="outlined"
              error={Boolean(error.password)}
              helperText={error.password ?? ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      onMouseDown={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />}
            {!passwordResetComplete && <TextField
                id="input-password"
                type={showPassword2 ? "text" : "password"}
                name="password2"
                label={t('confirmNewPassword')}
                variant="outlined"
                error={Boolean(error.password)}
                helperText={error.password ?? ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPassword2}
                        onMouseDown={(e) => {
                          e.preventDefault();
                        }}
                      >
                        {showPassword2 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
            />}
          {!passwordResetComplete && <Button type="submit" variant="contained" color="primary">
            {t('resetPassword')}
          </Button>}

        </form>

      </Card>


      {passwordResetComplete && <MoreInfoItem>
        <button className="link-button" onClick={() => toLogin()}>
          {t('gotoLogin')}
        </button>
      </MoreInfoItem>}
      </Container>
    </Container>
  );
}
