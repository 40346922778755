import React, { useEffect, useState } from "react";

import {  useNavigate } from "react-router-dom";
import { useRealmApp } from "../RealmApp";
import { useNavore } from "../../hooks/useNavore";
import { Modal, Box, Switch, Snackbar, Button, Stack, Alert, Container, Typography} from "@mui/material";

import { useParams } from 'react-router-dom'

import { useTranslation } from 'react-i18next';
import AsyncImage from "../common/AsyncImage";
import { VerticalSpacing } from "../common/Common";
import DocumentsPopup from "../common/DocumentsPopup";
import placeholder from '../../Assets/navore_placeholder.webp';
import { CertificationTable } from "../Certifications/CertificationTable";
import {  BSON } from "realm-web";


export function Certifications() {

    const { currentUser, updateViewLabel, isAdmin } = useRealmApp();
    const {loading, privateProfile, getTemporaryAdmins, addToTemporaryAdmins, getProducerPublicProfile, getCertificationsByProducer, updateProducerCertification, updateProducerProfile, getProducerAccess} = useNavore()
    const { id } = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation();

    const noResults = {
      success: null,
      error:null
    };

    const [actionResults, setActionResults] = React.useState(noResults);
    const [certifications, setCertifications] = React.useState(null)
    const [producer, setProducer] = React.useState(null)
    const [temporaryAccess, setTemporaryAccess] = React.useState(null)

    const [viewingURIs, setViewingURIs] = React.useState(null)
    const [viewingCertification, setViewingCertification] = React.useState(null)
    const [showDocViewer, setShowDocViewer] = React.useState(null)
    const [isProducerAdmin, setIsProducerAdmin] = React.useState(false)
    const [isAlreadyMemeber, setIsAlreadyMember] = React.useState(false)


    useEffect(() => {

        updateViewLabel(t('certifications'))
        if(loading)return;
        if(!currentUser){
            navigate("/", { replace: true });
        }

        if(producer === null && id !== null){
            getProducer(id)
        }else if (id === null){
            navigate(-1)
        }

      if(privateProfile && !isAdmin){
        navigate("/unauthorized", { replace: true });
      }

      if(producer && certifications === null){
        getCertifications()
      }

      if(producer && temporaryAccess === null){
        getAccessLists()
      }

    }, [loading, currentUser, privateProfile, actionResults, certifications, producer, isAlreadyMemeber]);

    function handleSnackbarClose(){
      setActionResults(noResults);
    }

    function snackbarOpen(){
      return (Boolean(actionResults.success) || Boolean(actionResults.error))
    }

    const getAccessLists = async() => { 

      const accessLists = await getProducerAccess(producer.producer_id)
      if(accessLists){
        checkAlreadyMember(accessLists.associates)
      }

      const tempAccess = await getTemporaryAdmins(privateProfile.uid, id)

      if(!tempAccess){
        return
      }

      setTemporaryAccess(tempAccess)
      setIsProducerAdmin(true)
    }


    const checkAlreadyMember = (associates) =>{


      for(const ass of associates){
        if(ass.uid === privateProfile.uid){
          console.log("Already Org MEMBER")
          setIsAlreadyMember(true)
          return
        }
      }

      setIsAlreadyMember(false)
    }

    const adminAccessExpires = () =>{

        if(!temporaryAccess){
          return "No access"
        }

        let exp = new Date(temporaryAccess.created)
        exp.setUTCDate(exp.getUTCDate() + 1)

        return exp.toLocaleDateString()  + " " +  exp.toLocaleTimeString()
    }


    const getCertifications = async() => {
      let certs = await getCertificationsByProducer(producer.producer_id)
      setCertifications(certs)

    }
    
    const getProducer = async(id) => {
      let orgs = await getProducerPublicProfile(id)

      // setIsProducerAdmin(privateProfile.producerAccess.edit.includes(producer.producer_id))
      setProducer(orgs)
    }

    const validationChanged = async(event) =>{
      let newVal = event.target.checked

      let newProd = JSON.parse(JSON.stringify(producer))
      newProd.validated = newVal

      setProducer(newProd)
      let result = await updateProducerProfile({validated:newVal}, producer.producer_id)

      if(result){
        setActionResults({
          success:'Producer Updated',
          error:null,
        })
      }else{
        setActionResults({
          success:null,
          error:'Failed to update producer',
        })
      }
      setProducer(result)
    }


    async function addMyselfAsAdmin(){

      setActionResults(noResults)

      if(isProducerAdmin){
        return
      }
      let Uid = privateProfile.uid
      let producerId = producer.producer_id
      let now = new Date()


      let data = {uid:Uid, producer_id:producerId, created:now, expired:false, producer_profile:{link:producerId}, user_profile:{link:Uid}}

      let result = await addToTemporaryAdmins(data)
      if(result){
        setActionResults({
          success:'Producer Added',
          error:null})

        setTemporaryAccess(data)
        setIsProducerAdmin(true)
      }else{
        setActionResults({
          success:null,
          error:'Error Adding to Admins'})
      }
    }


    const onAcceptedClicked = async(id, selected) => {
      
      console.log("Accepted clicked: ", id, selected)
      let newVal = !selected
      let dateUpdated = new Date().getTime() / 1000
      let result = await updateProducerCertification({approved:newVal, date_updated:String(dateUpdated)}, id)
      
      if(result === undefined){
        setActionResults({
          success:null,
          error:'Error Updating Document',
        })
      }

      let newCerts = []
      for(const cert of certifications){
        let oCert = JSON.parse(JSON.stringify(cert))
        if(cert._id.toString() === id){
          oCert.approved = newVal
          oCert.dateUpdated = dateUpdated
        }
        newCerts.push(oCert)

      }
      setCertifications(newCerts)

      setActionResults({
        success:'Document Updated',
        error:null,
      })
    }

    const formatURIs = (cert) => {

      let formatted = []
      for(const doc of cert.docs){
          let d = {uri:doc}
          formatted.push(d)
      }
      console.log(formatted)
      return formatted
    }

    const viewDocuments = (cert) =>{
      console.log("CERT: ", cert)

      let uris = formatURIs(cert)
      setViewingURIs(uris)
      setViewingCertification(cert)
      setShowDocViewer(true)
    }


    const toProfile = () => {
      let url = 'https://navore-consumer.vercel.app/consumer/profile/' + producer.url
      window.open(url, '_blank', 'noopener,noreferrer');
    }



    return (
      <Container className="main-container" maxWidth={false}>

        <Container maxWidth='md' disableGutters={true}>

          <VerticalSpacing value='40px'/>

            <Stack direction="row" justifyContent='space-between'>

                {producer && <Typography sx={{fontWeight:'bold'}} variant="h5">{producer.name}</Typography>}
                {producer && <Button variant='outlined' onClick={() => toProfile()}>{"To Profile ->"}</Button>}
                
            </Stack>

            <Stack direction="column" alignItems='left'>

              <Stack direction="row" alignItems='center'>

                {producer && <Typography variant="subititle">{t('verified') + "/" + t('enabled')}</Typography>}
                {producer && <Switch
                  checked={producer.validated}
                  
                  color="primary"
                  onChange={(event) => validationChanged(event)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />}
              </Stack>

              <Stack direction="row" alignItems='center'>

                {(isProducerAdmin && producer) ? <Typography>{"Temporary Admin Access Expires: " + adminAccessExpires()}</Typography> : 
                  <Button
                    variant="contained"
                    disabled={isAlreadyMemeber}
                    onClick={() => addMyselfAsAdmin()}
                    inputProps={{ 'aria-label': 'controlled' }}
                  >
                    <Typography variant="subititle">{isAlreadyMemeber ? t('alreadyAMemberOfOrg') : t('addMyselfAsAdminFor24')}</Typography>
                  </Button>
                }
                
            </Stack>
            </Stack>
          <VerticalSpacing value='20px'/>

            {certifications &&
              <CertificationTable certifications={certifications} organization={producer} viewDocs={(docs) => viewDocuments(docs)} handleAccepted={(id, selected) => onAcceptedClicked(id, selected)}/>
            }

        </Container>

        {viewingCertification && <Modal
                open={showDocViewer}
                onClose={() => setShowDocViewer(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >

                <DocumentsPopup
                    documents={viewingCertification.docs}
                    name={viewingCertification.name}
                    handleClose={() => setShowDocViewer(false)}
                />

         </Modal>}
       


        <Snackbar open={snackbarOpen()} autoHideDuration={4000} onClose={handleSnackbarClose}>
            {snackbarOpen() ? <Alert variant="filled" severity={actionResults.success ? "success" : "error"}>
            {actionResults.success ? actionResults.success : actionResults.error}
           </Alert> : <div/>}

        </Snackbar>
      </Container>

    );
}
