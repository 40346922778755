import React from "react"
import { Card, Button, Typography, Paper, Container, Stack, Box, Snackbar, Alert } from "@mui/material";
import { DocumentViewer } from 'react-documents';



const style = {
    position: 'absolute',
    top: '25%',
    left: '25%',
    height:'90%',
    transform: 'translate(-25%, -25%)',
    maxWidth: '98%',
    bgcolor: 'background.paper',
    overflowY:'auto',
    boxShadow: 24,
    p: 2
};
 



export default function DocumentsPopup ({documents, name,  handleClose}) {



    return(

        <Container maxWidth={false} disableGutters={true}  sx={style}>

                <Stack direction="row" justifyContent='space-between' alignItems='center'>

                    <Typography variant="body"  sx={{ fontWeight:'bold'}}>{name + " Documents"}</Typography>

                    <Button variant="contained" onClick={() => handleClose()}>Close</Button> 
                </Stack>


                {documents.map((url, index) => {
                        
                        return(
                            <Box height='100%'>
                                <Typography>{"Document " + String(index + 1) + " of " + documents.length}</Typography>
                                <DocumentViewer
                                    queryParams="hl=EN"
                                    url={url}
                                    viewer='url'

                                >
                                </DocumentViewer>
                            </Box>
                        )
                        
                    })} 

        </Container>
        
    )

}


