import React, { useEffect, useState } from "react";
import { useRealmApp } from "../RealmApp";
import { useNavore } from "../../hooks/useNavore";
import { useNavigate } from "react-router-dom";


// IMPORTS
import { Stack, Snackbar, Alert , TextField, Typography, Button, Container} from "@mui/material";
import { AdminTable } from "./AdminTable";
import { VerticalSpacing } from "../common/Common"


import { useShowLoader } from "../../hooks/util-hooks";
import { useTranslation } from 'react-i18next';



export function Admins() {

    const navigate = useNavigate()
    const { t } = useTranslation();

    // Authentication errors
    const noResults = {
      success: null,
      error:null,
    };
    

    const { currentUser, updateViewLabel, isAdmin } = useRealmApp();
    const { loading, privateProfile, editAdmins, getAdmins } = useNavore()
    
    const [admins, setAdmins] = React.useState(null)
    const [selectedAdmin, setSelectedAdmin] = React.useState(null)
    const [actionResults, setActionResults] = React.useState(noResults);

    const [newEmail, setNewEmail] = React.useState("")
    const showLoader = useShowLoader(loading, 200);

    
    useEffect(() => {

      updateViewLabel('Admins')
      
      if(loading)return;
      if(!currentUser){
        navigate("/", { replace: true });
      }

      if(privateProfile && !isAdmin){
        navigate("/unauthorized", { replace: true });
      }
      
      if(privateProfile !== null && admins === null){

        //here we need to stitch together associates info because useWatch doesn't pull in that info for producerAccess
        getAllAdmins()
      }

    }, [loading, currentUser, actionResults, privateProfile, admins]);


    async function getAllAdmins(){
      let admin = await getAdmins()
      setAdmins(admin.members)
    }


    async function removeAdmins(selected){

      setActionResults(noResults)
      console.log("removing...", selected)
      

      const functionName = "editSiteAdmins";
      const args = [privateProfile.uid, selected, 'remove'];

      let result = await currentUser.callFunction(functionName, ...args);
      if(result === null){
        setActionResults(() => ({
          success:null,
          error: "Failed to remove Associates",
        }));
        return
      }else if(result.success === false){
        setActionResults(() => ({
          success:null,
          error: result.message
        }));

        return
      }else if (result.success === true){

        let tadmins = JSON.parse(JSON.stringify(admins))
        let newAdmins = []
        for(const admin of tadmins){
          if(!selected.includes(admin._id) ){
            newAdmins.push(admin)
          }
        }

        console.log('ADMINS: ', newAdmins)

        setAdmins(newAdmins)

        setActionResults(() => ({
          error:null,
          success: "Associate(s) Successfully Removed"
        }));
      }

    }


    async function addEmail(){

      setActionResults(noResults)
      if(newEmail === ""){
        return
      }

      setNewEmail("")


      const findUserFunctionName = "getPublicProfileFromEmail";
      const addFunctionName = "editSiteAdmins";

      const findArgs = [newEmail];

      console.log("ARGS ", findArgs)
      const findResult = await currentUser.callFunction(findUserFunctionName, ...findArgs);
    
      if(findResult === null || findResult === undefined){

        setActionResults({
          success:null,
          error: "Error finding user",
        });
        return

      } else if(findResult.success === false){


        setActionResults({
          success:null,
          error: "User does not exist for email",
        });
        return
      }


      let id = findResult.data._id

      const addArgs = [privateProfile?.uid, id, 'add'];
      let result = await currentUser.callFunction(addFunctionName, ...addArgs);


      if(result.success === false){
        setActionResults({
          success:null,
          error: result.message
        });

        return
      }else if (result.success === true){


          let tadmins = JSON.parse(JSON.stringify(admins))
          tadmins.push(findResult.data)


          setAdmins(tadmins)
          setActionResults({
            error:null,
            success: "New Associate Added"
          });

        return
      }
    }




    function snackbarOpen(){
      return (Boolean(actionResults.success) || Boolean(actionResults.error))
    }

    const handleEmailFieldChange = (event) => {
        setNewEmail(event.target.value);
    };

    function handleSnackbarClose(){
      setActionResults(noResults);
    }

    function snackbarOpen(){
      return (Boolean(actionResults.success) || Boolean(actionResults.error))
    }


    const modalStyle = {
      width: "95%",
      transform: 'translate(0%, 25%)',
      bgcolor: 'background.paper',
      boxShadow: 24,
      borderRadius: '16px',
      padding:"5px",
      align:"right"
    };

    
    return (
      
      <Container className="main-container" maxWidth={false} disableGutters={true}>

        <VerticalSpacing value="20px"/>

        <Container maxWidth='sm' disableGutters>

              <Typography align="left" variant="h5" color="primary">
                  {t("addAdmin")}
              </Typography>

              <Stack direction="row" spacing={2}>

                <TextField
                    id="input-email"
                    name="email"
                    value={newEmail}
                    size="small"
                    onChange={handleEmailFieldChange}
                    label={t("emailAddress")}
                    variant="outlined"
                    fullWidth
                    error={Boolean(actionResults.error)}
                    helperText={actionResults.error ?? ""}
                />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={addEmail}>
                    {t("add")}
                </Button>

              </Stack>
         </Container>

        <VerticalSpacing value='10px'/>

        <Container maxWidth='sm' disableGutters>

              {admins && <AdminTable admins={admins} currentUser={privateProfile}  deleteAdmins={(selected) => removeAdmins(selected)}/>}

              <Snackbar open={snackbarOpen()} autoHideDuration={3000} onClose={handleSnackbarClose} >

                  {snackbarOpen() ? <Alert variant="filled" severity={actionResults.success ? "success" : "error"}>
                    {actionResults.success ? actionResults.success : actionResults.error}
                  </Alert> : <div/>}

              </Snackbar>


        </Container>

        

  </Container>
  );
}
