import React, { useEffect, useState } from "react";

import {  useNavigate } from "react-router-dom";
import { useRealmApp } from "../RealmApp";
import { useNavore } from "../../hooks/useNavore";
import {Button, Stack, Snackbar, Alert, Container, Typography, Card} from "@mui/material";

import { useTranslation } from 'react-i18next';
import { VerticalSpacing } from "../common/Common";


export function Home() {

    const { currentUser, updateViewLabel, fullyRemoveUser, isAdmin } = useRealmApp();
    const {loading, privateProfile} = useNavore()

    const navigate = useNavigate()
    const { t } = useTranslation();

    const noResults = {
      success: null,
      error:null
    };
    const [actionResults, setActionResults] = React.useState(noResults);


    useEffect(() => {

      updateViewLabel(t('home'))

      if(loading)return;
      if(!currentUser){
        navigate("/", { replace: true });
      }

      if(privateProfile && !isAdmin){
        navigate("/unauthorized", { replace: true });
      }
    
    }, [loading, currentUser, privateProfile, isAdmin]);


    function handleSnackbarClose(){
      setActionResults(noResults);
    }

    function snackbarOpen(){
      return (Boolean(actionResults.success) || Boolean(actionResults.error))
    }

    const toAdmins = () =>{
      navigate("/admins", { replace: false });
    }

    const logout = async () =>{
      await fullyRemoveUser()
      navigate("/", {replace:true})
    }

    const toCertifications = () =>{
      navigate("/approval", { replace: false });
    }

    const toAdminAccess = () =>{
      navigate("/access", { replace: false });
    }

    const toProducerAccess = () =>{
      navigate("/producers", { replace: false });
    }

    return (
      <Container className="main-container" maxWidth={false}>
        <Container maxWidth='sm' disableGutters={true}>
        <VerticalSpacing value='50px'/>

        {/*FILTERING*/}

        <Card sx={{padding:'20px'}} variant="outlined">
          <Typography variant="h5">Home</Typography>
          <VerticalSpacing value='24px'/>

          <Stack direction='column' spacing={3}>
            <Button variant="outlined" onClick={() => toAdmins()}>Admins</Button>
            <Button variant="outlined" onClick={() => toCertifications()}>Producer Approval</Button>
            <Button variant="outlined" onClick={() => toAdminAccess()}>Admin Access</Button>
            <Button variant="contained" onClick={() => logout()}>Logout</Button>

          </Stack>
        </Card>
        </Container>

        <Snackbar open={snackbarOpen()} autoHideDuration={4000} onClose={handleSnackbarClose}>
            {snackbarOpen() ? <Alert variant="filled" severity={actionResults.success ? "success" : "error"}>
            {actionResults.success ? actionResults.success : actionResults.error}
           </Alert> : <div/>}

        </Snackbar>
      </Container>

    );
}
