import React from "react";
import { useRealmApp } from "../RealmApp";

import {Container, Button, Typography, Card, Stack} from "@mui/material";


import { VerticalSpacing } from "../common/Common";


import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";


export function Unauthorized() {

  const realmApp = useRealmApp();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();


  const logout = async () =>{
    await realmApp.fullyRemoveUser()
    navigate("/login", {replace:true})
  }

  return (

    <Container className="main-container" maxWidth={false}>
        <Container maxWidth='sm' disableGutters={true}>
        <VerticalSpacing value='50px'/>

        {/*FILTERING*/}

        <Card sx={{padding:'20px'}} variant="outlined">
          <Typography variant="h5">{t("Unauthorized")}</Typography>
          <Typography variant="body">{t("Your account does not have access to this site.")}</Typography>
          <VerticalSpacing value='24px'/>
          <Stack direction='column' spacing={3}>
            <Button variant="contained" onClick={() => logout() }>Logout</Button>
          </Stack>
        </Card>
        </Container>

      </Container>


  );
}



