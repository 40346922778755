
import React, { useState } from "react";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import  List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import {HorizontalSpacing, VerticalSpacing, ListButton} from "./Common"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { AppName } from "./AppName";
import { useRealmApp } from "../RealmApp";
import { Typography } from "@mui/material";


export function TopNavigationBar(){

  const { currentUser } = useRealmApp();
  const navigate = useNavigate()
  const { t } = useTranslation();
  const [showBackButton, setShowBackButton] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)


  React.useEffect(() =>{
      if(!currentUser){
        toSplash()
      }

      if(window.location.pathname === "/home"){
        setShowBackButton(false)
      }else{
        setShowBackButton(true)
      }

  }, [currentUser, window.location.pathname])

  
  const toSplash = () =>{
    setIsDrawerOpen(false)
    navigate("/", { replace: true });
  }


  function backAction(){
    setIsDrawerOpen(false)

    navigate(-1, {replace:false})
  }

  function toAdmins(){
    setIsDrawerOpen(false)

    navigate("/admins", { replace: false });
  }

  function toHome(){
    setIsDrawerOpen(false)

    navigate("/home", { replace: false });
  }

  function toApproval(){
    setIsDrawerOpen(false)

    navigate("/approval", { replace: false });
  }

  function toAdminAccess(){
    setIsDrawerOpen(false)

    navigate("/access", { replace: false });
  }

  // const toProducerAccess = () =>{
  //   setIsDrawerOpen(false)

  //   navigate("/producers", { replace: false });
  // }

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.secondary.dark,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  const StyledDrawer = styled(Drawer)(({ theme }) => ({
    position: 'fixed',
    zIndex: theme.zIndex.appBar - 1
   
  }));

  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.dark
    
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));


  return(

    <Box sx={{ flexGrow: 1 }}>
      <AppBar elevation={1} color="secondary" position="fixed">
        <Toolbar>

            {showBackButton && <IconButton
              size="large"
              edge="start"
              color="inherit"
              sx={{ mr: 2 }}
              variant="outlined" 
              onClick={() => backAction()}>

                <ArrowBackIosIcon color="white"/>

            </IconButton>}

            <HorizontalSpacing width={5}/>
            <AppName/>

            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            >
            {isDrawerOpen ?
            <CloseIcon/>
            : 
            <MenuIcon />
            }
            
          </IconButton>


          <StyledDrawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
              <VerticalSpacing value={50}/>
              <List sx={{minWidth:'300px'}}>

                {/* <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </Search> */}
                <Box sx={{padding:'10px'}} justifyContent='flex-end'>
                  <Typography align="center" variant="h5">Menu</Typography>
                </Box>
                <VerticalSpacing value={10}/>

                <ListButton
                  title={t('home')}
                  onClick={() => toHome()}
                />

                <ListButton
                  title={t('admins')}
                  onClick={() => toAdmins()}
                />

                <ListButton
                  title={t('producerAccess')}
                  onClick={() => toAdminAccess()}
                />

                {/* <ListButton
                  title={t('producerAccess')}
                  onClick={() => toProducerAccess()}
                /> */}

                <ListButton
                  title={t('producerApproval')}
                  onClick={() => toApproval()}
                />
            </List>
          </StyledDrawer>
        </Toolbar>
      </AppBar>
    </Box>

  )
  
}
