import { red } from "@mui/material/colors";

export const colors = {
  green:{
    main:"#4caf50",
    light:"#a5d6a7",
    dark:"#1b5e20"
  },
  brown:{
    main:"#795548",
    light:"#8d6e63",
    dark:"#3e2723"
  },
  black:{
    main:"#000000",
    light:"#333333",
    dark:"#000000"
  },
  white:{
    main:"#FFFFFF",
    light:"#FFFFFF",
    dark:"#FFFFFF"
  },
  gray:{
    main:"#9e9e9e",
    light:"#f5f5f5",
    dark:"#424242"
  },
  contrastText:{
    light:"#FFF",
    dark:"#000"
  },

  red,
};
